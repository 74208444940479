import { useLazyQuery } from '@apollo/client';
import { UserRoles } from '@constants/index';
import { AuthenticatedRoutes, Routes, RoutesByUserRoles } from '@constants/routes';
import { isInternalUser, useMode } from '@contexts/ModeContextProvider';
import { GET_PLAN_DOCUMENTS } from '@gql/GetPlanDocuments';
import useTokenVerification, { TokenStatus } from '@hooks/useTokenVerification';
import { getUserInfo } from '@lib/requests';
import { useQuery } from '@tanstack/react-query';
import { getItem, removeItem, setItem } from '@utils/localStorage';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { createContext, useCallback, useContext, useEffect, useState } from 'react';
import { User } from '../interfaces';

enum AuthState {
  // Initial state, before any checks have been made
  INITIAL = 'INITIAL',
  // Checking the validity of a token or verifying user's credentials
  VERIFYING = 'VERIFYING',
  // The user is confirmed to be authenticated
  AUTHENTICATED = 'AUTHENTICATED',
  // The user is confirmed to be unauthenticated (e.g., no token found)
  UNAUTHENTICATED = 'UNAUTHENTICATED',
}

enum AuthEvent {
  // User tries to access a page without permission (e.g. user visits /agents)
  ACCESS_DENIED = 'ACCESS_DENIED',
  // User successfully logs in
  LOGIN = 'LOGIN',
  // User successfully logs out
  LOGOUT = 'LOGOUT',
  // User is redirected based on a redirect value in local storage
  REDIRECT_LOCAL = 'REDIRECT_LOCAL',
  // User is redirected based on a redirect value in the magic link
  REDIRECT_QUERY = 'REDIRECT_QUERY',
  // userInfo request fails
  REQUEST_ERROR = 'REQUEST_ERROR',
}

type AuthenticatedContext = {
  authState: AuthState;
  authenticatedUser: any;
  planDocumentsData: any;
  loadingPlanDocuments: boolean;
  networkStatus: number;
  handleLogout: (arg: User) => void;
  handleResendLink: () => void;
  isAgent: boolean;
  isOpenbook: boolean;
  loading: boolean;
  localUser: User;
  reFetchUser: () => void;
  setAuthenticatedUser: React.Dispatch<React.SetStateAction<User>>;
  setLocalUser: (arg: User) => void;
  tokenStatus: TokenStatus;
  isBedrock: boolean | null;
  redirect?: boolean;
};

// Checks if a pathname is contained in a list of paths
const matchesPaths = ({ pathname, paths }) => {
  if (!pathname) return false;
  return paths?.some((path: string) => pathname.includes(path));
};

const AuthenticatedContext = createContext<AuthenticatedContext>({} as AuthenticatedContext);

const AuthenticatedContextProvider = ({ isBedrock, children }) => {
  // Resources
  const posthog = usePostHog();
  const mode = useMode();
  const router = useRouter();

  // State
  const [authState, setAuthState] = useState(AuthState.INITIAL);
  const [authenticatedUser, setAuthenticatedUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Router info
  const { pathname, isReady } = router;
  const { token, redirect: queryRedirect } = router.query;

  // Hooks
  const { tokenStatus, handleResendLink, localUser, setLocalUser } = useTokenVerification(token as string);

  // Variables
  const agentRoles = [UserRoles.AGENT, UserRoles.SDR, UserRoles.INTERNAL];
  const isAgent = agentRoles.includes(localUser?.role);
  const isOpenbook = authenticatedUser?.creation_method === 'OPENBOOK';
  const isAuthenticatedPath = matchesPaths({ paths: AuthenticatedRoutes, pathname });
  const dashboardRoute = isAgent ? Routes.agents : Routes.dashboard;
  const localToken = localUser?.token;
  const userId = localUser?.id;

  // Check if the user is allowed to access the given route.
  const isAccessAllowed = useCallback(
    (route: string) => {
      // All users can access the pages used for authentication.
      if (matchesPaths({ paths: [Routes.login, Routes.verify, Routes.checkEmail], pathname: route })) {
        return true;
      }

      const allowedRoutes = RoutesByUserRoles[localUser?.role];
      return matchesPaths({ paths: allowedRoutes, pathname: route });
    },
    [localUser]
  );

  // Handle user logout
  const handleLogout = () => {
    posthog.reset();
    setAuthenticatedUser(null);
    setLocalUser(null);
    posthog.capture(AuthEvent.LOGOUT, { userId });
    router.push(Routes.login);
  };

  // Get User Plan Docs
  const [
    getPlanDocuments,
    { data: planDocsData, loading: loadingPlanDocs, networkStatus },
  ] = useLazyQuery(GET_PLAN_DOCUMENTS, { notifyOnNetworkStatusChange: true });

  // User info query
  const { refetch } = useQuery({
    queryKey: ['userInfo', userId],
    refetchOnWindowFocus: false,
    queryFn: async () => getUserInfo({ userId }),
    enabled: !!(userId && localUser?.token),
    onSuccess: (data) => {
      getPlanDocuments();
      setAuthenticatedUser(data);
      posthog.identify(data.id, {
        is_internal_user: isInternalUser(mode),
        agency: data.agency?.name,
      });
    },
    onError: (error) => {
      setAuthState(AuthState.UNAUTHENTICATED);
      posthog.capture(AuthEvent.REQUEST_ERROR, { userId, error });
      handleLogout();
      router.push(Routes.login);
    },
  });

  // Set the correct authState based on the conditions.
  useEffect(() => {
    // Not ready!
    if (!isReady) return;

    // If the page doesn't require authentication, return early.
    if (!isAuthenticatedPath && ![Routes.verify, Routes.login].includes(pathname as Routes)) {
      return;
    }

    // Handle an unauthenticated user (after logout), visiting the login page.
    // i.e. start the login flow again.
    if (pathname === Routes.login) {
      setAuthState(AuthState.INITIAL);
      return;
    }

    // Handle behavior from the /verify page.
    if (localToken && (isAuthenticatedPath || pathname === Routes.verify)) {
      if (authenticatedUser) {
        setAuthState(AuthState.AUTHENTICATED);
      } else {
        setAuthState(AuthState.VERIFYING);
      }
      return;
    }

    // Handle unauthenticated users.
    if (isAuthenticatedPath && !localToken) {
      setAuthState(AuthState.UNAUTHENTICATED);
    }
  }, [isReady, isAuthenticatedPath, pathname, authenticatedUser, localToken, router]);

  // Handle page redirects based on authState.
  useEffect(() => {
    // Logout unauthenticated users.
    if (queryRedirect) {
      posthog.capture(AuthEvent.REDIRECT_QUERY, { userId, path: queryRedirect });
      setItem('redirect', queryRedirect as string);
    }

    if (authState === AuthState.UNAUTHENTICATED) {
      // Set the current path as the redirect in local storage.
      // We will use this later to redirect the user to their original destination.
      if (![Routes.verify, Routes.login, Routes.checkEmail].includes(pathname as Routes)) {
        setItem('redirect', router.asPath);
      }
      router.push(Routes.login);
      return;
    }

    // Handle redirects from /verify.
    if ((authState === AuthState.VERIFYING || authState === AuthState.AUTHENTICATED) && pathname === Routes.verify) {
      // If the user is associated with multiple accounts, redirect them to the choose account page.
      if (!isAgent && localUser?.multipleUsers?.length > 1) {
        router.push(Routes.chooseAccount);
        return;
      }

      const localRedirect = getItem('redirect');
      if (localRedirect) posthog.capture(AuthEvent.REDIRECT_LOCAL, { userId, path: localRedirect });

      // Redirect the user to their respective dashboard.
      const redirect = localRedirect || dashboardRoute;
      const accessAllowed = isAccessAllowed(redirect);
      if (accessAllowed) router.push(redirect);
      removeItem('redirect');
      return;
    }

    // If an authenticated user tries to access a path for which they don't have access,
    // redirect the user to their respective dashboard.
    if (authState === AuthState.AUTHENTICATED && !isAccessAllowed(pathname)) {
      posthog.capture(AuthEvent.ACCESS_DENIED, { userId, path: pathname });
      router.push(dashboardRoute);
    }
  }, [pathname, router, isAgent, dashboardRoute, isAccessAllowed, queryRedirect, authState, authenticatedUser]);

  // Handle loading state.
  // If the user is authenticated set the loading state to false.
  // This is to prevent the user from seeing the login page for a split second.
  useEffect(() => {
    if (authState === AuthState.AUTHENTICATED) {
      posthog.capture(AuthEvent.LOGIN, { userId });
      setLoading(false);
    }
  }, [authState, pathname]);

  return (
    <AuthenticatedContext.Provider
      value={{
        authState,
        authenticatedUser,
        planDocumentsData: planDocsData,
        loadingPlanDocuments: loadingPlanDocs,
        networkStatus,
        handleLogout,
        handleResendLink,
        isAgent,
        isOpenbook,
        localUser,
        loading,
        reFetchUser: refetch,
        setAuthenticatedUser,
        setLocalUser,
        tokenStatus,
        isBedrock,
      }}
    >
      {children}
    </AuthenticatedContext.Provider>
  );
};

const useAuthenticatedContextProvider = () => useContext(AuthenticatedContext);

export { AuthenticatedContextProvider as default, useAuthenticatedContextProvider };
