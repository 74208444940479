import { Collapse, Stack } from '@chakra-ui/react';
import TextLink from '@components/ra/interactive/TextLink';
import { useVirgil } from '@contexts/VirgilContext';

const SuggestedQuestions = () => {
  const { conversationId, emitQuestion, messageHistory } = useVirgil();
  const show = messageHistory.length === 0;

  const suggestedQuestions = [
    'What is Part B?',
    'When am I eligible for Medicare?',
    'Do I need supplemental coverage?',
  ];

  return (
    <Collapse in={show} animateOpacity>
      <Stack alignItems="center">
        {suggestedQuestions.map((question) => (
          <TextLink
            key={question}
            className="suggested-question"
            text={question}
            onClick={() => emitQuestion({ question, conversationId })}
          />
        ))}
      </Stack>
    </Collapse>
  );
};

export default SuggestedQuestions;
