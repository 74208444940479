import { Box, Flex, Stack } from '@chakra-ui/react';
import Body1 from '@components/bedrock/typography/Body1';

export enum Role {
  User = 'user',
  Assistant = 'assistant',
}

const Message = ({ message }) => {
  const isUser = message.role === Role.User;

  return (
    <Flex justifyContent={isUser ? 'right' : 'left'} minHeight={10} direction="column">
      <Stack
        spacing={6}
        color={isUser ? 'white' : 'black'}
        bg={isUser ? 'primary.600' : 'gray.100'}
        ml={isUser && 'auto'}
        py={5}
        px={[2, 2, 3, 4]}
        borderRadius={isUser ? '20px 0px 20px 20px' : '0px 20px 20px 20px'}
        whiteSpace="pre-line"
        textAlign={isUser ? 'right' : 'left'}
      >
        <Body1>
          <Box dangerouslySetInnerHTML={{ __html: message.content.trim() }} />
        </Body1>
      </Stack>
    </Flex>
  );
};

export default Message;
