import { FormControl, Input as ChakraInput, InputGroup, InputRightElement, Textarea, Spinner } from '@chakra-ui/react';
import { CheckIcon, WarningIcon } from '@chakra-ui/icons';
import { useField, useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { InputState } from '../../types';
import InputLabel from './InputLabel';
import { colors } from '../../constants';
import { useUpdateUser } from '@components/crm/shared/queries';
import useStandardToast from './useStandardToast';
import { getChanges } from '../../utils';

export default function Input({
  autosave = false,
  error = null,
  hide = false,
  label = null,
  loading = false,
  maxLength = null,
  name,
  onChange = null,
  softRequired = false,
  type = 'text',
  userId = null,
  rightElement = null,
  ...rest
}) {
  const [field, meta] = useField(name);
  const showToast = useStandardToast();
  const { values, initialValues, setFieldValue } = useFormikContext();
  const queryClient = useQueryClient();
  const [state, setState] = useState(InputState.IDLE);

  const changeSet = getChanges(initialValues, values);

  const getRightElement = () => {
    let rightElement;

    if (error) {
      rightElement = <WarningIcon color="red" />;
    }

    if (state === InputState.LOADING) {
      rightElement = <Spinner size="xs" color="gray" />;
    }

    if (state === InputState.SAVED) {
      rightElement = <CheckIcon color="green.400" />;
    }

    return rightElement ? <InputRightElement>{rightElement}</InputRightElement> : null;
  };

  const { mutateAsync: updateUser } = useUpdateUser({
    userId,
    values,
    changeSet,
    onSuccess: () => {
      showToast('Lead Saved!', 'success');

      // handle states for spinner / check mark.
      queryClient.invalidateQueries({ queryKey: ['userDetails'] });

      setTimeout(() => {
        setState(InputState.SAVED);
        setTimeout(() => {
          setState(InputState.IDLE);
        }, 500);
      }, 1000);
    },
  });

  useEffect(() => {
    setState(loading ? InputState.LOADING : InputState.IDLE);
  }, [loading]);

  useEffect(() => {
    if (meta.error || meta.initialValue === meta.value) return;

    if (meta.value === '') setFieldValue(name, null);

    if (autosave) {
      const timeoutId = setTimeout(() => {
        setState(InputState.LOADING);
        updateUser();
      }, 750);
      return () => {
        clearTimeout(timeoutId);
      };
    }

    if (onChange) {
      onChange(field.value);
    }
  }, [field.value, meta.error]);

  field.onBlur = () => {
    // If the field is not autosave enabled or the value hasn't changed, don't save.
    if (type !== 'textarea' || !autosave || initialValues[name] === values[name]) return;

    setState(InputState.LOADING);
    updateUser();
  };

  return (
    <FormControl display={hide ? 'none' : 'unset'}>
      {label ? <InputLabel label={label} /> : null}
      <InputGroup>
        {type === 'textarea' ? (
          <Textarea
            bg="#FAF5FF"
            focusBorderColor={meta.error ? 'red.600' : 'purple.500'}
            id={name}
            maxLength={maxLength}
            {...field}
            {...rest}
          />
        ) : (
          <ChakraInput
            autoComplete="off"
            bg={softRequired ? colors.softRequired : 'white'}
            focusBorderColor={meta.error ? 'red.600' : 'purple.500'}
            id={name}
            maxLength={maxLength}
            type={type}
            {...rest}
            {...field}
          />
        )}
        {getRightElement() || <InputRightElement>{rightElement}</InputRightElement>}
      </InputGroup>
    </FormControl>
  );
}
