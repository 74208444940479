export const cmsPublications = {
  '02110-medigap-guide-health-insurance':
    'https://www.medicare.gov/publications/02110-medigap-guide-health-insurance.pdf',
  choosingAMedigapPolicy: 'https://www.medicare.gov/publications/02110-medigap-guide-health-insurance.pdf',
  outpatientServicesForPartB: 'https://www.medicare.gov/publications/02118-Part-B-Outpatient-Services-Payment.pdf',
  hospiceBenefits: 'https://www.medicare.gov/publications/02154-medicare-hospice-benefits.pdf',
  surgerySecondOpinion: 'https://www.medicare.gov/publications/02173-getting-a-second-opinion-before-surgery.pdf',
  whoPaysFirst:
    'https://www.medicare.gov/publications/02179-Medicare-and-other-health-benefits-your-guide-to-who-pays-first.pdf',
  preventativeServices: 'https://www.medicare.gov/publications/10110-Your-Guide-to-Medicare-Preventive-Services.pdf',
  benefits: 'https://www.medicare.gov/publications/10116-your-medicare-benefits.pdf',
  choosingAHospital: 'https://www.medicare.gov/publications/10181-Guide-to-Choosing-a-Hospital.pdf',
  mentalHealthBenfits: 'https://www.medicare.gov/publications/10184-Medicare-and-Your-Mental-Health-Benefits.pdf',
  therapyServies: 'https://www.medicare.gov/publications/10184-Medicare-and-Your-Mental-Health-Benefits.pdf',
  diabetesCoverage: 'https://www.medicare.gov/publications/11022-medicare-coverage-of-diabetes-supplies.pdf',
  enrollingInPartA: 'https://www.medicare.gov/publications/11036-enrolling-medicare-part-a-part-b.pdf',
  medicareAndYou: 'https://www.medicare.gov/Pubs/pdf/10050-Medicare-and-You.pdf',
  medicareCobraGuide: 'https://triagecancer.org/wp-content/uploads/2022/09/2022-Medicare-COBRA-Quick-Guide.pdf',
  stayingHealthy: 'https://www.medicare.gov/publications/11100-staying-healthy.pdf',
  medicareDrugCoverage: 'https://www.medicare.gov/publications/11109-Medicare-Drug-Coverage-Guide.pdf',
  pharmacyCoverageRules: 'https://www.medicare.gov/publications/11136-pharmacies-formularies-coverage-rules.pdf',
  choosingDrugCoverage: 'https://www.medicare.gov/publications/11163-Compare-Medicare-Drug-Coverage.pdf', // 0 chunks
  mapdEnrollmentPeriods: 'https://www.medicare.gov/publications/11219-understanding-medicare-part-c-d.pdf',
  medicareMedicaid: 'https://www.medicare.gov/publications/11306-Medicare-Medicaid.pdf', // 0 chunks
  payingWithExtraHelp: 'https://www.medicare.gov/publications/11324-Extra-Help-Pay-Right-Amount.pdf',
  coverageOutsideUs: 'https://www.medicare.gov/publications/11037-medicare-coverage-outside-united-states.pdf',
  durableMedicalEquipment:
    'https://www.medicare.gov/media/publication/11045-medicare-coverage-of-dme-and-other-devices.pdf',
  wheelchairScooter: 'https://www.medicare.gov/publications/11046-medicare-wheelchair-scooter.pdf',
  pace: 'https://www.medicare.gov/publications/11341-Quick-Facts-PACE.pdf', // 0 chunks
  longTermCare: 'https://www.medicare.gov/publications/11347-What-are-long-term-care-hospitals.pdf', // 0 chunks
  homeHealthBenefit: 'https://www.medicare.gov/publications/11357-home-health-getting-started.pdf',
  skilledNursingCare:
    'https://www.medicare.gov/publications/11359-Medicare-Skilled-Nursing-Facility-Care-Getting-Started.pdf', // 0 chunks
  kidneyTransplantDialysis: 'https://www.medicare.gov/publications/11360-medicare-dialysis-kidney-transplant.pdf',
  hospiceBenefitsGettingStarted: 'https://www.medicare.gov/publications/11361-medicare-hospice-getting-started.pdf',
  payingThroughSocialSecurity: 'https://www.medicare.gov/publications/11400-withholding-medicare-drug-premium.pdf',
  hospitalBenefits: 'https://www.medicare.gov/publications/11408-Medicare-and-Your-Hospital-Benefits.pdf',
  medicaid: 'https://www.medicare.gov/publications/11409-getting-started-understand-medicaid-basics.pdf',
  lowerDrugCosts: 'https://www.medicare.gov/publications/11417-3-ways-lower-medicare-drug-costs.pdf',
  incomeAffectsPremiums: 'https://www.medicare.gov/publications/11469-income-and-drug-premiums.pdf',
  coverageOfDialysisKidney: 'https://www.medicare.gov/Pubs/pdf/10128-medicare-coverage-esrd.pdf',
};
