import { titleCase } from 'title-case';
import { startCase } from 'lodash';
import { brackets } from '../static';
import { UserDetailsUpdate, SubsidyType, Lead } from '../types';

const convertStringToBoolean = (value: string) => {
  if (value === 'Yes') return true;
  if (value === 'No') return false;
  return undefined;
};

export const findBracketById = (id: string, type: 'joint' | 'individual' | null) => {
  let bracketsOfType;
  if (type === 'individual' || !type) bracketsOfType = brackets.individual_bracket;
  if (type === 'joint') bracketsOfType = brackets.joint_bracket;
  return bracketsOfType.find((bracket) => String(bracket.id) === id);
};

export const findBracket = (userBracket, filedIndividualTaxes: boolean | null) => {
  const bracketsOfType = filedIndividualTaxes === false ? brackets.joint_bracket : brackets.individual_bracket;
  return bracketsOfType.find((bracket) => String(bracket.monthly_payment) === String(userBracket?.monthly_payment));
};

export const convertTaxStatusToBoolean = (status: 'individual' | 'joint'): boolean | null => {
  if (status === 'joint') return false;
  if (status === 'individual') return true;
  return null;
};

export const convertFiledIndividualTaxesToStatus = (value: boolean): 'individual' | 'joint' | null => {
  if (value === true) return 'individual';
  if (value === false) return 'joint';
  return null;
};

export const convertBooleanToString = (value: boolean) => {
  if (value === true) return 'true';
  if (value === false) return 'false';
  return '';
};

const handleSubsidyPercentage = (user) => {
  if (user.subsidyType === SubsidyType.PARTIAL_SUBSIDY && !user.subsidyPercentage) {
    return 25;
  }
  if (user.subsidyType === SubsidyType.PARTIAL_SUBSIDY && user.subsidyPercentage) {
    return Number(user.subsidyPercentage);
  }
  return null;
};

// Formats a date string as MM/DD/YYYY
export const formatUserDate = (date: string) => {
  if (!date) return '';

  const parsed = date.substring(0, 10).split('-');
  const year = parsed[0];
  const month = parsed[1];
  const day = parsed[2];
  return `${month}/${day}/${year}`;
};

export const formatDate = (date: string) => {
  if (!date) return '';

  const parsed = date.slice(0, 10);
  return parsed;
};

export const extractCmsId = (cmsId: string) => {
  if (!cmsId) return {};
  const hNumber = cmsId[0].toUpperCase() + cmsId.slice(1, 5);
  const planId = cmsId.toUpperCase().slice(6, 9);
  const segmentId = +cmsId.toUpperCase().slice(10, 13) || '0';

  return { hNumber, planId, segmentId };
};

// Formats a phone number like: (555) 555-5555
export const formatUserPhoneNumber = (phoneNumber: string) => {
  if (!phoneNumber) return '';

  const cleaned = phoneNumber.replace(/\D/g, '');

  if (cleaned.length === 10) {
    const areaCode = cleaned.substring(0, 3);
    const prefix = cleaned.substring(3, 6);
    const lineNumber = cleaned.substring(6);
    return `(${areaCode}) ${prefix}-${lineNumber}`;
  }

  if (cleaned.length === 11 && cleaned.startsWith('1')) {
    const areaCode = cleaned.substring(1, 4);
    const prefix = cleaned.substring(4, 7);
    const lineNumber = cleaned.substring(7);
    return `(${areaCode}) ${prefix}-${lineNumber}`;
  }

  return phoneNumber;
};

const formatIncomeBracketsForUpdate = ({
  bracketId,
  effectiveDate,
  filedIndividualTaxes,
}: {
  bracketId: string;
  effectiveDate: string;
  filedIndividualTaxes: boolean;
}) => {
  if (!bracketId || !effectiveDate || !filedIndividualTaxes) return null;

  const bracket = findBracketById(bracketId, filedIndividualTaxes ? 'individual' : 'joint');

  return {
    incomeBracketBracketYear: effectiveDate ? effectiveDate.slice(0, 4) : '',
    incomeBracketIncomeYear: effectiveDate ? String(parseInt(effectiveDate.slice(0, 4), 10) - 2) : '',
    incomeBracketLowerBound: bracket?.lower_bound || null,
    incomeBracketUpperBound: bracket?.upper_bound || null,
    incomeBracketMonthlyPayment: bracket?.monthly_payment || null,
  };
};

export const getQualificationsForUpdate = (user) => ({
  groupOver20: handleFieldValue(user.groupOver20),
  hasMedicareCard: handleFieldValue(user.hasMedicareCard),
  individualCoverage: user.individualCoverage,
  plannedRetirement: user.plannedRetirement || undefined,
  takingSocialSecurityBenefits: handleFieldValue(user.takingSocialSecurityBenefits),
  veteranCoverage: user.veteranCoverage,
});

export const handleFieldValue = (value: string) => {
  if (typeof value === 'boolean') return value;
  if (value === 'true') return true;
  if (value === 'false') return false;
  if (value == '') return null;
  return value;
};

/* Function to calculate the changed fields between two form objects.
 * This is used in our Formik input handlers to submit an object
 * that includes only the fields that have been changed.
 */
export const getChanges = (obj1, obj2) => {
  const changedAttributes = {};
  const allKeys = new Set([...Object.keys(obj1), ...Object.keys(obj2)]);

  allKeys.forEach((key) => {
    if (obj1[key] !== obj2[key]) {
      changedAttributes[key] = handleFieldValue(obj2[key]);
    }
  });

  return changedAttributes;
};

/*
 *
 *  This function formats the current state of a form into a update lead payload.
 *
 *  See: https://github.com/Fair-Square-Medicare/back_end/blob/master/services/UserService/index.ts#L820
 *
 */

interface FormatUpdateLeadPayload {
  changeSet: any;
  userId: string;
  values: any;
}

export const formatUpdateLeadPayload = ({ userId, values, changeSet }: FormatUpdateLeadPayload): UserDetailsUpdate => {
  const mutatedChangeSet = { ...changeSet };

  // Run each value through handleFieldValue() to convert any form values
  // to the correct values for the payload.
  Object.keys(mutatedChangeSet).forEach((key) => {
    mutatedChangeSet[key] = handleFieldValue(mutatedChangeSet[key]);
  });

  const updatePayload: UserDetailsUpdate = {
    userId,
    userInfo: {
      userId,
      ...mutatedChangeSet,
    },
  };

  const incomeBracketInfo = formatIncomeBracketsForUpdate({
    bracketId: mutatedChangeSet.incomeBracket || values.incomeBracket,
    effectiveDate: mutatedChangeSet.desiredEffectiveDate || values.desiredEffectiveDate,
    filedIndividualTaxes: mutatedChangeSet.filedIndividualTaxes || values.filedIndividualTaxes,
  });

  if (incomeBracketInfo) {
    updatePayload.userInfo = { ...updatePayload.userInfo, ...incomeBracketInfo };
  }

  const qualificationKeys = [
    'groupOver20',
    'hasMedicareCard',
    'individualCoverage',
    'plannedRetirement',
    'takingSocialSecurityBenefits',
    'veteranCoverage',
  ];

  const isQualificationChange = qualificationKeys.some((key) => Object.keys(mutatedChangeSet).includes(key));

  if (isQualificationChange) {
    updatePayload.userQualification = getQualificationsForUpdate(values);
    updatePayload.userQualification.agentId = values.agentId;
  }

  // Delete qualifications from form object before sending to update lead route.
  // Qualifications are sent in the userQualification object.
  qualificationKeys.forEach((key) => {
    delete updatePayload.userInfo[key];
  });

  return updatePayload;
};

export default {
  // Formats a user's name for display.
  formatUserName: (user) => {
    if (!user) return '';

    let nameString = '';

    if (user.first_name?.trim()) {
      nameString = user.first_name;
    }

    if (user.preferred_name?.trim()) {
      nameString = `${nameString} (${user.preferred_name})`;
    }

    if (user.middle_initial?.trim()) {
      nameString = `${nameString} ${user.middle_initial}`;
    }

    if (user.last_name?.trim()) {
      nameString = `${nameString} ${user.last_name}`;
    }

    if (!nameString) return 'No Name';

    return nameString;
  },

  // Formats a birthday / dob string for display.
  formatBirthday: (date: string) => {
    if (!date) return '';

    const yearInMs = 365 * 24 * 60 * 60 * 1000;
    const parsed = formatUserDate(date);
    const diff = +new Date() - +new Date(date);
    const age = diff / yearInMs;
    const years = Math.floor(age);
    const months = Math.floor((age - years) * 12);
    return `${parsed} (${years}yr ${months}mo)`;
  },

  formatEnum: (enumString: string) => {
    if (!enumString) return '';
    return startCase(enumString.toLowerCase());
  },

  getPartnerById: (id, partners) => {
    if (!id || !partners) return;

    return partners.find((partner) => partner.id === id);
  },

  // Format's a plan type like: MAPD (HMO)
  formatPlanType: (plan) => plan.plan_type + (plan.plan_sub_type ? ` (${plan.plan_sub_type})` : ''),

  // Formats a user (from getUserProfile) to be used within the formik lead form.
  getInitialValuesForUser: (user, agentId = null): Lead | null => {
    if (!user) return null;

    const qualification = user.Qualifications[0];
    const incomeBracket = findBracket(user.profile.income_brackets, user.profile.filed_individual_taxes);

    return {
      birthday: user.birthday?.slice(0, 10), // cut date part from UTC date.
      desiredEffectiveDate: formatDate(user.profile.desired_effective_date),
      email: user.email,
      emailSetting: user.profile.email_setting,
      filedIndividualTaxes: convertBooleanToString(user.profile.filed_individual_taxes),
      firstName: user.first_name,
      followUpDate: formatDate(user.profile.follow_up_date),
      gender: user.profile.gender,
      groupOver20: qualification?.group_over_20,
      hasMedicareCard: convertBooleanToString(qualification?.has_medicare_card),
      incomeBracket: incomeBracket?.id,
      individualCoverage: qualification?.individual_coverage,
      isSmoker: convertBooleanToString(user.profile.is_smoker),
      lastName: user.last_name,
      leadOwnerId: user.lead_owner_id,
      leadStatus: user.profile.lead_status,
      mailingAddressCity: user.profile.mailing_address?.city,
      mailingAddressCounty: user.profile.mailing_address?.county,
      mailingAddressFipsCode: user.profile.mailing_address?.fips,
      mailingAddressLineOne: user.profile.mailing_address?.address_line_one,
      mailingAddressLineTwo: user.profile.mailing_address?.address_line_two,
      mailingAddressState: user.profile.mailing_address?.state,
      mailingAddressZipCode: user.profile.mailing_address?.zip_code,
      medicareNumber: user.profile.medicare_number,
      middleInitial: user.middle_initial,
      noSaleCategory: user.profile.no_sale_category,
      noSaleReason: user.profile.no_sale_reason,
      notes: user.profile.notes,
      partAEffectiveDate: formatDate(user.profile.part_a_effective_date),
      partBEffectiveDate: formatDate(user.profile.part_b_effective_date),
      partnerId: user.profile.partner_id,
      plannedRetirement: formatDate(qualification?.planned_retirement),
      preferredName: user.preferred_name,
      primaryAddressCity: user.profile.primary_address?.city,
      primaryAddressCounty: user.profile.primary_address?.county,
      primaryAddressFipsCode: user.profile.primary_address?.fips,
      primaryAddressLineOne: user.profile.primary_address?.address_line_one,
      primaryAddressLineTwo: user.profile.primary_address?.address_line_two,
      primaryAddressState: user.profile.primary_address?.state,
      primaryAddressZipCode: user.profile.primary_address?.zip_code,
      primaryPhoneNumber: user.primary_phone_number,
      secondaryPhoneNumber: user.secondary_phone_number,
      ssn: user.profile.ssn,
      subsidyPercentage: Number(user?.subsidy?.subsidy_percentage) || null,
      subsidyType: user?.subsidy?.subsidy_type,
      takingSocialSecurityBenefits: convertBooleanToString(qualification?.taking_social_security_benefits),
      timezone: user.profile.timezone,
      veteranCoverage: qualification?.veteran_coverage,
      agentId,
    };
  },

  // Title case a string and remove underscores.
  cleanEnumString: (str) => titleCase(str.replace(/_/g, ' ').toLowerCase()),

  // handles general title casing
  handleTitleCase: (str: string) => {
    if (!str) return '';
    return titleCase(str.toLowerCase());
  },

  formatPhoneForCalendly: (phoneNumber: string) => {
    if (!phoneNumber) return '';
    const alreadyFormatted = phoneNumber[0] === '1' || phoneNumber[0] === '+';
    return alreadyFormatted ? phoneNumber : `1${phoneNumber}`;
  },
  getNextEffectiveDates: () => {
    const dates = [];
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    for (let i = 0; i < 5; i++) {
      let month = currentMonth + i;
      let year = currentYear;

      if (month > 12) {
        month -= 12;
        year++;
      }

      const formattedMonth = month.toString().padStart(2, '0');
      const date = `${formattedMonth}/01/${year}`;
      dates.push(date);
    }

    return dates;
  },
};
