import { ApolloProvider } from '@apollo/client';
import { ChakraProvider, ColorModeScript } from '@chakra-ui/react';
import {
  BedrockRoutes,
  Routes,
  agentBrokerRoutes,
  agentRoutes,
  agentStateRoutes,
  nonUserRoutes,
} from '@constants/routes';
import AuthenticatedContextProvider from '@contexts/AuthenticatedContextProvider';
import ModeContextProvider from '@contexts/ModeContextProvider';
import SemContextProvider from '@contexts/SemContextProvider';
import VirgilProvider from '@contexts/VirgilContext';
import FacebookPixel from '@lib/FacebookPixel';
import * as fbq from '@lib/fbpixel';
import { initPostHog } from '@lib/postHog';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import { defaultBedrockOpenGraph, defaultFsmOpenGraph } from '@utils/opengraph';
import axios from 'axios';
import { DefaultSeo } from 'next-seo';
import type { AppProps } from 'next/app';
import Head from 'next/head';
import { useRouter } from 'next/router';
import Script from 'next/script';
import posthog from 'posthog-js';
import { ErrorBoundary, captureMessage } from '@sentry/react';
import { PostHogProvider } from 'posthog-js/react';
import { useEffect, useMemo, useState } from 'react';
import bedrockTheme from '../chakra-ui/bedrockTheme';
import fsmTheme from '../chakra-ui/fsmTheme';
import ogTheme from '../chakra-ui/ogTheme';
import WizardContextProvider from '../contexts/WizardContextProvider';
import { useApollo } from '../lib/apolloClient';
import ErrorPage from '@components/common/ErrorPage';
import '../styles/global.css';

const handleRouteChange = () => {
  // Manually log page view to PostHog.
  posthog.capture('$pageview');

  // Log page view to internal /analytics.
  const path = window.location.href;
  const localUser = window.localStorage.getItem('user');

  if (localUser && localUser !== 'undefined') {
    const parsed = JSON.parse(localUser);
    try {
      axios.post(`${process.env.GRAPHQL_SERVER_ENDPOINT}/analytics`, {
        path,
        userId: parsed.id,
      });
    } catch (err) {
      // Do nothing
    }
  }
};

// Handle top level error boundary behavior.
const fallbackRender = ({ error }) => {
  // Temporary: Double log error as sentry message.
  captureMessage('Client Side Error', { extra: { error } });

  return <ErrorPage />;
};

initPostHog();

const queryClient = new QueryClient();

function App({ Component, pageProps }: AppProps) {
  const apolloClient = useApollo(pageProps.initialApolloState);
  const router = useRouter();

  // Bedrock Redirect
  const [isBedrock, setIsBedrock] = useState(null);

  useEffect(() => {
    const isBedrockHost = location.host.startsWith('app.');
    const isBedrockPath = BedrockRoutes.includes(location.pathname as any);
    setIsBedrock(isBedrockHost);
    if (isBedrockHost && !isBedrockPath) {
      router.push(Routes.index);
    }
  }, [router]);

  // Posthog + Postgres Pageview Tracking
  useEffect(() => {
    router.events.on('routeChangeComplete', handleRouteChange);
    return () => {
      router.events.off('routeChangeComplete', handleRouteChange);
    };
  });

  const isSeoPage = useMemo(() => {
    const isArticleMatch = router.asPath.toLowerCase().startsWith(Routes.articles);
    const isMsSeoMatch = router.asPath.toLowerCase().startsWith(Routes.bestMedicareSupplement);
    const isMaSeoMatch = router.asPath.toLowerCase().startsWith(Routes.topRatedMedicareAdvantage);
    return isArticleMatch || isMsSeoMatch || isMaSeoMatch;
  }, [router]);

  // Theme
  const theme = useMemo(() => {
    const raPaths = [
      Routes.about,
      Routes.aca,
      Routes.accountCallUs,
      Routes.accountSoa,
      Routes.accountSurveyUpdatePharmacies,
      Routes.accountSurveyUpdatePrescriptions,
      Routes.accountSurveyUpdateProviders,
      Routes.accountSurveyUpdateSoa,
      Routes.accountSurveyUpdateZipcode,
      Routes.accountVirgil,
      Routes.aepIntro,
      Routes.aepOptOut,
      Routes.aepPharmacies,
      Routes.aepPlans,
      Routes.aepPrescriptions,
      Routes.aepProviders,
      Routes.aepRecommendations,
      Routes.aepZipCode,
      Routes.articles,
      Routes.articles,
      Routes.bestMedicareSupplement,
      Routes.callUs,
      Routes.checkEmail,
      Routes.chooseAccount,
      Routes.chooseYourAgent,
      Routes.cmsComplaint,
      Routes.dashboard,
      Routes.demo,
      Routes.drxLink,
      Routes.helloVirgil,
      Routes.index,
      Routes.invite,
      Routes.login,
      Routes.medicareAdvantage,
      Routes.medicareAgents,
      Routes.medicareBrokers,
      Routes.medicareCardProtector,
      Routes.medicareSupplement,
      Routes.medicarewala,
      Routes.newsletter,
      Routes.newToMedicare,
      Routes.quotes,
      Routes.resourcesHowToApply,
      Routes.socialSecurityCalculator,
      Routes.stopSpam,
      Routes.topRatedMedicareAdvantage,
      Routes.verify,
      Routes.virgil,
      ...agentStateRoutes,
      ...agentBrokerRoutes,
      ...agentRoutes,
    ];

    const useFsmTheme = Boolean(
      raPaths.find((raPath) => {
        const isPathMatch = router.asPath.split('?')[0].toLowerCase() === raPath;
        return isPathMatch || isSeoPage;
      })
    );

    // Cannot use isBedrock bc on first client render it's not set yet.
    const useBedrockTheme = Boolean(
      typeof location !== 'undefined' &&
        (location.host.startsWith('app.') || nonUserRoutes.includes(router.pathname as any))
    );

    return useBedrockTheme ? bedrockTheme : useFsmTheme ? fsmTheme : ogTheme;
  }, [router]);

  const isRedirecting = isBedrock && !BedrockRoutes.includes(router.pathname as any);
  const renderPage = (isSeoPage || isBedrock !== null) && !isRedirecting;
  const showBedrockOpenGraph =
    (typeof location !== 'undefined' && location.host.startsWith('app.')) ||
    (pageProps && pageProps.clientHost && pageProps.clientHost.includes('app.'));
  const seoProps = showBedrockOpenGraph ? defaultBedrockOpenGraph : defaultFsmOpenGraph;
  const faviconPath = showBedrockOpenGraph
    ? '/favicon/bedrock/favicon.ico'
    : '/ra/logo/symbols/fsm-symbol-color-50.svg';

  return (
    <>
      <DefaultSeo {...seoProps} />
      <Head>
        <link rel="apple-touch-icon" sizes="57x57" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="60x60" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="72x72" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="76x76" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="114x114" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="120x120" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="144x144" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="152x152" href={faviconPath} />
        <link rel="apple-touch-icon" sizes="180x180" href={faviconPath} />
        <link rel="manifest" href="/favicon/manifest.json" />
        <meta name="msapplication-TileColor" content="#ffffff" />
        <meta name="msapplication-TileImage" content={faviconPath} />
        <meta name="theme-color" content="#ffffff" />
        <link rel="icon" href={faviconPath} />
      </Head>
      {process.env.NEXT_PUBLIC_VERCEL_ENV === 'production' ? (
        <>
          <Script
            id="fb-pixel"
            strategy="afterInteractive"
            dangerouslySetInnerHTML={{
              __html: `
                  !function(f,b,e,v,n,t,s)
                  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
                  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
                  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
                  n.queue=[];t=b.createElement(e);t.async=!0;
                  t.src=v;s=b.getElementsByTagName(e)[0];
                  s.parentNode.insertBefore(t,s)}(window, document,'script',
                  'https://connect.facebook.net/en_US/fbevents.js');
                  fbq('init', ${fbq.FB_PIXEL_ID});
                `,
            }}
          />
          <Script
            id="gtag-base"
            dangerouslySetInnerHTML={{
              __html: `(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-WXPQBSG');
              gtag('config', 'AW-626357108');`,
            }}
          />
          <Script
            id="gtag-phone-snippet"
            dangerouslySetInnerHTML={{
              __html: `gtag('config', 'AW-626357108/aK-uCNn0usQDEPTm1aoC', {
                'phone_conversion_number': '(888) 973-0354'
                });`,
            }}
          />
        </>
      ) : null}
      <ColorModeScript initialColorMode={fsmTheme.config.initialColorMode} />
      <FacebookPixel>
        <ApolloProvider client={apolloClient}>
          <PostHogProvider client={posthog}>
            <QueryClientProvider client={queryClient}>
              {/* Content Level */}
              <ChakraProvider theme={theme}>
                <ErrorBoundary fallback={fallbackRender}>
                  <ModeContextProvider>
                    <SemContextProvider>
                      <WizardContextProvider>
                        <AuthenticatedContextProvider isBedrock={isBedrock}>
                          <VirgilProvider>{renderPage && <Component {...pageProps} />}</VirgilProvider>
                        </AuthenticatedContextProvider>
                      </WizardContextProvider>
                    </SemContextProvider>
                  </ModeContextProvider>
                </ErrorBoundary>
              </ChakraProvider>
              <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
          </PostHogProvider>
        </ApolloProvider>
      </FacebookPixel>
    </>
  );
}

export default App;
