import { Flex } from '@chakra-ui/react';
import Spinner from './Spinner';

const SpinnerPage = () => {
  return (
    <Flex
      position="sticky"
      top={0}
      left={0}
      right={0}
      bottom={0}
      height="100vh"
      justifyContent="center"
      alignItems="center"
      bg="transparent"
    >
      <Spinner />
    </Flex>
  );
};

export default SpinnerPage;
