/** 2025 IRMAA Brackets */
export const brackets = {
  individual_bracket: [
    {
      id: 1,
      amount: '$106,000 or less',
      monthly_payment: 185.0,
      lower_bound: 0,
      upper_bound: 106000,
    },
    {
      id: 2,
      amount: 'above $106,000 up to $133,000',
      monthly_payment: 259.6,
      lower_bound: 106000,
      upper_bound: 133000,
    },
    {
      id: 3,
      amount: 'above $133,000 up to $167,000',
      monthly_payment: 370.0,
      lower_bound: 133000,
      upper_bound: 167000,
    },
    {
      id: 4,
      amount: 'above $167,000 up to $200,000',
      monthly_payment: 480.9,
      lower_bound: 167000,
      upper_bound: 200000,
    },
    {
      id: 5,
      amount: 'above $200,000 and less than $500,000',
      monthly_payment: 591.9,
      lower_bound: 200000,
      upper_bound: 500000,
    },
    {
      id: 6,
      amount: '$500,000 or above',
      monthly_payment: 628.9,
      lower_bound: 500000,
      upper_bound: 10000000,
    },
  ],
  joint_bracket: [
    {
      id: 1,
      amount: '$212,000 or less',
      monthly_payment: 185.0,
      lower_bound: 0,
      upper_bound: 212000,
    },
    {
      id: 2,
      amount: 'above $212,000 up to $266,000',
      monthly_payment: 259.0,
      lower_bound: 212000,
      upper_bound: 266000,
    },
    {
      id: 3,
      amount: 'above $266,000 up to $334,000',
      monthly_payment: 370.0,
      lower_bound: 266000,
      upper_bound: 334000,
    },
    {
      id: 4,
      amount: 'above $334,000 up to $400,000',
      monthly_payment: 480.9,
      lower_bound: 334000,
      upper_bound: 400000,
    },
    {
      id: 5,
      amount: 'above $400,000 and less than $750,000',
      monthly_payment: 591.9,
      lower_bound: 400000,
      upper_bound: 750000,
    },
    {
      id: 6,
      amount: '$750,000 and above',
      monthly_payment: 628.9,
      lower_bound: 750000,
      upper_bound: 10000000,
    },
  ],
};
