import aetna from '@public/ra/carrier-logos/aetna.png';
import anthem from '@public/ra/carrier-logos/anthem.png';
import bcbs from '@public/ra/carrier-logos/bcbs.webp';
import bsCalifornia from '@public/ra/carrier-logos/bs-california.png';
import cigna from '@public/ra/carrier-logos/cigna.png';
import gpm from '@public/ra/carrier-logos/gpm.png';
import humana from '@public/ra/carrier-logos/humana.svg';
import mutualOfOmaha from '@public/ra/carrier-logos/mutual-of-omaha.png';
import regence from '@public/ra/carrier-logos/regence.png';
import uhc from '@public/ra/carrier-logos/uhc.svg';
import wellcare from '@public/ra/carrier-logos/wellcare.png';

export enum UserRoles {
  AGENT = 'AGENT',
  USER = 'USER',
  SDR = 'SDR',
  INTERNAL = 'INTERNAL',
}

export enum StorageKeys {
  isSem = 'isSem',
  dynamicPhoneNumber = 'dynamicPhoneNumber',
}

// For testing paths
export const msPattern = /\/best-medicare-supplement/;
export const maPattern = /\/top-rated-medicare-advantage/;
export const blogPattern = /\/articles/;

export const CarrierLogos = {
  humana,
  aetna,
  anthem,
  wellcare,
  uhc,
  regence,
  mutualOfOmaha,
  gpm,
  bsCalifornia,
  bcbs,
  cigna,
} as const;

export const getCarrierLogo = (name: string) => {
  const cleanedName = name.toLowerCase();

  if (cleanedName.includes('aetna')) return CarrierLogos.aetna;
  if (cleanedName.includes('anthem')) return CarrierLogos.anthem;
  if (cleanedName.includes('wellcare')) return CarrierLogos.wellcare;
  if (cleanedName.includes('unitedhealth') || cleanedName.includes('uhc')) return CarrierLogos.uhc;
  if (cleanedName.includes('regence')) return CarrierLogos.regence;
  if (cleanedName.includes('omaha')) return CarrierLogos.mutualOfOmaha;
  if (cleanedName.includes('gpm')) return CarrierLogos.gpm;
  if (cleanedName.includes('humana')) return CarrierLogos.humana;
  if (cleanedName.includes('california')) return CarrierLogos.bsCalifornia;
  if (cleanedName.includes('blue')) return CarrierLogos.bcbs;
  if (cleanedName.includes('cigna')) return CarrierLogos.cigna;
};
