import { Flex, Stack, Text } from '@chakra-ui/react';
import { Mode, useMode } from '@contexts/ModeContextProvider';
import { useVirgil } from '@contexts/VirgilContext';

const renderValue = (value) => {
  if (typeof value === 'object') {
    return JSON.stringify(value, null, 2);
  }

  return value;
};

const Dev = () => {
  // Resources
  const { devContent } = useVirgil();
  const mode = useMode();

  if (mode !== Mode.DEV || !Object.keys(devContent).length) {
    return null;
  }

  return (
    <Stack bg="black" p={4} spacing={0} fontFamily="Courier" fontWeight="bold" borderRadius={20}>
      {Object.keys(devContent).map((key, index) => (
        <Flex key={index}>
          <Text mr={2} color={devContent[key].color}>
            {key}:
          </Text>
          <Text key={index} color={'white'}>
            {renderValue(devContent[key].value)}
          </Text>
        </Flex>
      ))}
    </Stack>
  );
};

export default Dev;
