import { Flex } from '@chakra-ui/react';
import { useVirgil } from '@contexts/VirgilContext';
import DevTool from './DevTool';
import Header from './Header';
import MessageHistory from './MessageHistory';
import MessageInput from './MessageInput';
import SuggestedQuestions from './SuggestedQuestions';

const FsmChat = () => {
  // Resources
  const { chatStackRef } = useVirgil();

  return (
    <Flex
      ref={chatStackRef}
      maxW="680px"
      h="100%"
      py={[12, 12, 16, '80px']}
      alignItems="center"
      mx="auto"
      gap={[5, 5, 8, 8]}
      overflow="scroll"
      flexDir="column"
      sx={{
        '::-webkit-scrollbar': {
          display: 'none',
        },
      }}
    >
      <MessageInput />
      <Flex flexDir="column" w="100%" gap={6} flexGrow={1} pb={['140px', '94px', '60px']}>
        <Header />
        <SuggestedQuestions />
        <MessageHistory />
        <DevTool />
      </Flex>
    </Flex>
  );
};

export default FsmChat;
