import jwt from 'jsonwebtoken';
import { useState } from 'react';
import { useRouter } from 'next/router';
import { removeItem } from '@utils/localStorage';
import useLocalStorage from '@hooks/useLocalStorage';
import { verifyToken, sendToken } from '@components/login/requests';
import { Routes } from '@constants/routes';
import { StorageKeys } from '@constants/index';

export enum TokenStatus {
  ERROR = 'ERROR',
  EXPIRED = 'EXPIRED',
  INVALID = 'INVALID',
  USED = 'USED',
  VALID = 'VALID',
}

const useTokenVerification = (initialToken: string) => {
  const [tokenStatus, setTokenStatus] = useState(null);
  const [loading, setLoading] = useState(false);

  const router = useRouter();
  const [localUser, setLocalUser] = useLocalStorage('user', {});

  const handleResendLink = async () => {
    await sendToken(localUser.email);
    router.push(Routes.checkEmail);
  };

  const handleVerifyToken = async (token: string) => {
    setLoading(true);

    const { data } = await verifyToken(token, localUser?.token);
    setTokenStatus(data.status);

    if (data.status === TokenStatus.VALID) {
      const payload = jwt.decode(data.token);
      setLocalUser({
        ...localUser,
        email: payload.email,
        id: payload.userId,
        multipleUsers: payload.multipleUsers,
        role: payload.role,
        token: data.token,
        sessionId: payload.sessionId,
      });

      // Remove dpn on login
      removeItem(StorageKeys.dynamicPhoneNumber);
    } else {
      setLocalUser({
        ...localUser,
        email: data.email,
      });
      setTokenStatus(data.status);
    }
  };

  if (initialToken && !loading) {
    handleVerifyToken(initialToken);
  }

  return {
    localUser,
    setLocalUser,
    handleResendLink,
    verifyLoading: loading,
    tokenStatus,
  };
};

export default useTokenVerification;
