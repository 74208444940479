import { Routes } from './routes';

export enum PhoneNumbers {
  GOOGLE = 8889730354,
  MS_SEO = 8885079540,
  MA_SEO = 8885704402,
  BLOG = 8883762028,
  LANDING = 8885726033,
  CHOOSE_YOUR_AGENT = 8889152840,
  NATIONAL = 8884542383,
  MEDICAREWALA = 8888591403,
  NERDWALLET_A = 8882922091,
  NERDWALLET_B = 8884315028,
  NERDWALLET_C = 8884195386,
  VIRGIL = 8889070757,
}

// Routes with specific Phone Number
export const routeToNumber = {
  [Routes.medicarewala]: PhoneNumbers.MEDICAREWALA,
};

// TODO VERIFY THIS WORKS AS EXPECTED (variant updates in client)
export const getCallUsData = (dynamicPhoneNumber: PhoneNumbers | null) => {
  if (dynamicPhoneNumber) {
    return {
      href: `tel:${hyphenatePhoneNumber(dynamicPhoneNumber)}`,
      text: `Call Us: ${hyphenatePhoneNumber(dynamicPhoneNumber)}`,
    };
  }

  return {
    href: Routes.callUs,
    text: 'Call Us',
  };
};

export const getPhoneLink = (phoneNumber: PhoneNumbers) => {
  if (!phoneNumber) return '';

  return `tel:${hyphenatePhoneNumber(phoneNumber)}`;
};

export const hyphenatePhoneNumber = (phoneNumber: number) => {
  if (!phoneNumber) return 'None';

  return phoneNumber
    .toString()
    .replace(/[^0-9]/g, '')
    .replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3');
};

export const cleanPhoneNumber = (phoneNumber: string) => phoneNumber.replace(/[^0-9]/g, '');
