import { Flex, Stack } from '@chakra-ui/react';
import Header2 from '@components/ra/typography/Header2';
import Body1 from '@components/ra/typography/Body1';
import Image from 'next/image';
import { Routes } from '@constants/routes';
import DebuggingIcon from '@public/ra/icons/debugging.svg';
import Button from '@components/ra/interactive/Button';

interface Props {
  message?: string;
  showButton?: Boolean;
}

const ErrorPage = ({ message, showButton = false }: Props) => {
  return (
    <Flex height="100vh" direction="column" justifyContent="center" alignItems="center" p={'1rem'} bg="transparent">
      <Image alt="Error Icon" width={200} height={200} src={DebuggingIcon} />
      <Stack alignItems="center" spacing={4}>
        <Header2>An error has occured.</Header2>
        <Body1>Our engineers have been notified and will investigate the issue.</Body1>
        {message && <Body1 fontWeight="bold">{message}</Body1>}
        {showButton && (
          <Button id="error-page-dashboard" href={Routes.dashboard}>
            Go To Dashboard
          </Button>
        )}
      </Stack>
    </Flex>
  );
};

export default ErrorPage;
