import { useToast } from '@chakra-ui/react';

const useStandardToast = () => {
  const toast = useToast();
  /** valid status: "info" | "warning" | "success" | "error" | "loading" */
  const showToast = (message, status, duration = 3000) => {
    toast({
      title: message,
      status,
      duration: status === 'error' ? 3000 : duration,
      isClosable: true,
      variant: 'subtle',
      containerStyle: {
        bottom: '200px',
      },
    });
  };

  return showToast;
};

export default useStandardToast;
