import { CalendlyAppointments, ICalendlyAppointment, SubsidyType } from './types';

export const colors = {
  softRequired: '#FAF5FF',
};

export const qualificationsDictionary = {
  aepConversion: {
    label: 'AEP Opportunity',
    color: 'cyan',
    value: 'aep_conversion',
  },
  creditableGroupCoverage: {
    label: 'Creditable Group',
    color: 'gray',
    value: 'creditable_group_coverage',
  },
  creditableVeteranCoverage: {
    label: 'Creditable Veteran',
    color: 'yellow',
    value: 'creditable_veteran_coverage',
  },
  enrolled: {
    label: 'Enrolled',
    color: 'green',
    value: 'enrolled',
  },
  incomplete: {
    label: 'Incomplete Qual.',
    color: 'red',
    value: 'qualifications_incomplete',
  },
  medicaid: {
    label: 'Medicaid',
    color: 'blue',
    value: 'medicaid',
  },
  needsMedicare: {
    label: 'Needs Medicare',
    color: 'orange',
    value: 'needs_medicare',
  },
  readyToEnroll: {
    label: 'Ready to Enroll',
    color: 'green',
    value: 'ready_to_enroll',
  },
  retiringSoon: {
    label: 'Retiring Soon',
    color: 'teal',
    value: 'retiring_soon',
  },
  youngerThan64: {
    label: 'Under 64',
    color: 'blue',
    value: 'younger_than_64',
  },
} as const;

export const timezoneMappings = {
  HAST: 'US/Hawaii',
  AK: 'US/Alaska',
  PT: 'US/Pacific',
  MT: 'US/Mountain',
  CT: 'US/Central',
  ET: 'US/Eastern',
  AZ: 'US/Arizona',
};

export const calendlyAppointmentTypes: ICalendlyAppointment[] = [
  {
    name: CalendlyAppointments.NEW_TO_MEDICARE_RR,
    slug: 'fair-square-medicare/new-to-medicare-consult',
    label: 'New to Medicare Round Robin',
    length: 45,
  },
  {
    name: CalendlyAppointments.COSTCO_RR,
    slug: 'fsm-costco/new-to-medicare-consult',
    label: 'Costco Round Robin',
    length: 45,
  },
  {
    name: CalendlyAppointments.HOUR_RR,
    slug: 'fair-square-medicare/new-to-medicare-consultation-60-minutes',
    label: 'Round Robin Extra Time',
    length: 60,
  },
  {
    name: CalendlyAppointments.MEET_YOUR_MEDICARE_GUIDE,
    slug: 'fair-square-medicare/meet-your-medicare-guide',
    label: 'Meet Your Medicare Guide',
    length: 15,
  },
  {
    name: CalendlyAppointments.RETIREMENT_COVERAGE_GUIDANCE,
    slug: 'theresa-166/retirement-coverage-guidance',
    label: 'Retirement Coverage Guidance',
    length: 15,
  },
  {
    name: CalendlyAppointments.AEP_MAPD_RR,
    slug: 'fair-square-medicare/fsm-aep-consultation-mapd',
    label: 'AEP MAPD Round Robin',
    length: 30,
  },
  {
    name: CalendlyAppointments.AEP_PDP_RR,
    slug: 'fair-square-medicare/fsm-aep-consultation-pdp',
    label: 'AEP PDP Round Robin',
    length: 30,
  },
];

export const medigapPlanNames = [
  'G',
  'N',
  'G Extra',
  'G Inspire',
  'G Innovative',
  'G High Deductible',
  'A',
  'B',
  'C',
  'D',
  'F',
  'K',
  'L',
  'M',
  'F High Deductible',
  'F Innovative',
  'N Innovative',
  'Basic',
  'Basic w/Riders',
  'Extended Basic',
  'Extended Basic w/Riders',
  'Core Plan',
  'Supplement 1',
  'Supplement 1A',
  '$20/$50 Copayment',
  '25% Cost Sharing',
  '50% Cost Sharing',
  '75% Cost Sharing',
  'Waiver State Plan',
];

export const extraHelpSubsidies = [
  {
    value: '',
    label: 'None',
  },
  {
    value: SubsidyType.DUAL,
    label: 'Receives help from Medicaid',
  },
  {
    value: SubsidyType.FULL_DUAL,
    label: 'Receives supplemental security income',
  },
  {
    value: SubsidyType.MEDICARE_SAVINGS_PROGRAM,
    label: 'Receives help from a Medicare Savings Program',
  },
  {
    value: SubsidyType.PARTIAL_SUBSIDY,
    label: 'Receives partial help through social security',
    subsidyPercentage: 25,
  },
  {
    value: SubsidyType.FULL_SUBSIDY_SOCIAL_SECURITY,
    label: 'Receives full help through social security',
  },
];

export const subsidyPercentages = [
  {
    label: '25%',
    value: 25,
  },
  {
    label: '50%',
    value: 50,
  },
  {
    label: '75%',
    value: 75,
  },
];
