export enum AgencyState {
  CONTRACTED = 'CONTRACTED',
  DEFAULT = 'DEFAULT',
  TRIAL = 'TRIAL',
}

export enum OrderByArgs {
  ORDER_BY_CREATED_AT = 'ORDER_BY_CREATED_AT',
  ORDER_BY_FOLLOW_UP_DATE = 'ORDER_BY_FOLLOW_UP_DATE',
  ORDER_BY_APPOINTMENT_DATE = 'ORDER_BY_APPOINTMENT_DATE',
  ORDER_BY_FOLLOW_AND_APPOINTMENT = 'ORDER_BY_FOLLOW_AND_APPOINTMENT',
}

export enum WhereClauseArgs {
  ALL = 'ALL',
  FOLLOW_UP_TODAY = 'FOLLOW_UP_TODAY',
  UPCOMING_APPOINTMENTS = 'UPCOMING_APPOINTMENTS',
  MISSING_QUALIFICATION = 'MISSING_QUALIFICATION',
  HOT_LEADS = 'HOT_LEADS',
  LIVE_LEADS = 'LIVE_LEADS',
  LIVE_LEADS_WO_NEXT_STEP = 'LIVE_LEADS_WO_NEXT_STEP',
  COSTCO_CLIENTS = 'COSTCO_CLIENTS',
}

enum CreationMethod {
  AIRCALL_WEBHOOK = 'AIRCALL_WEBHOOK',
  LAPRO_UPLOAD = 'LAPRO_UPLOAD',
  MA_WIZARD = 'MA_WIZARD',
  MS_WIZARD = 'MS_WIZARD',
  NEWSLETTER = 'NEWSLETTER',
  CALENDLY = 'CALENDLY',
  WIZARD = 'WIZARD',
  CRM = 'CRM',
}

export enum EmailSetting {
  UNSUBSCRIBE_ALL = 'UNSUBSCRIBE_ALL',
  // UNSUBSCRIBE_MARKETING - we haven't defined what this means yet (hide from agents).
  // UNSUBSCRIBE_OTC - we haven't defined what this means yet (hide from agents).
}

export enum LeadStatus {
  FIRST_CALL_COMPLETE = 'FIRST_CALL_COMPLETE',
  SECOND_CALL_COMPLETE = 'SECOND_CALL_COMPLETE',
  CLOSED = 'CLOSED',
  NO_SALE = 'NO_SALE',
  STILL_WORKING = 'STILL_WORKING',
  DECEASED = 'DECEASED',
}

export enum IndividualCoverageType {
  GROUP_COVERAGE = 'GROUP_COVERAGE',
  INDIVIDUAL_COBRA = 'INDIVIDUAL_COBRA',
  ACA = 'ACA',
  NO_EXISTING_COVERAGE = 'NO_EXISTING_COVERAGE',
  MEDICAID = 'MEDICAID',
  MEDICARE = 'MEDICARE',
}

export enum VeteranCoverageType {
  VETERAN_AFFAIRS = 'VETERAN_AFFAIRS',
  TRICARE_FOR_LIFE = 'TRICARE_FOR_LIFE',
  CHAMP_VA = 'CHAMP_VA',
  NO_VETERAN_COVERAGE = 'NO_VETERAN_COVERAGE',
}

export enum TimeZones {
  AK = 'AK',
  AZ = 'AZ',
  CT = 'CT',
  ET = 'ET',
  HAST = 'HAST',
  MT = 'MT',
  PT = 'PT',
}

export interface UserDetailsUpdate {
  userId: string;
  userInfo: UserInfo;
  userQualification?: UserQualification;
}

interface UserQualification {
  hasMedicareCard: boolean | string;
  individualCoverage: IndividualCoverageType;
  plannedRetirement: string;
  takingSocialSecurityBenefits: boolean | string;
  veteranCoverage: VeteranCoverageType;
  agentId?: string;
}

export interface UserInfo {
  appointmentDate?: string;
  birthday?: string;
  creationMethod?: CreationMethod;
  desiredEffectiveDate?: string;
  email?: string;
  emailSetting?: EmailSetting;
  filedIndividualTaxes?: boolean;
  firstName?: string;
  followUpDate?: string;
  gender?: string;
  hasEmployerCoverage?: boolean;
  hasMedicareCard?: boolean;
  incomeBracketBracketYear?: string;
  incomeBracketIncomeYear?: string;
  incomeBracketLowerBound?: number;
  incomeBracketMonthlyPayment?: number;
  incomeBracketUpperBound?: number;
  isSmoker?: boolean;
  isVeteran?: boolean;
  laproId?: string;
  lastName?: string;
  leadOwnerId?: string;
  leadStatus?: string;
  mailingAddressCity?: string;
  mailingAddressCounty?: string;
  mailingAddressFipsCode?: string;
  mailingAddressLineOne?: string;
  mailingAddressLineTwo?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  medicareNumber?: string;
  middleInitial?: string;
  notes?: string;
  paidMedicareTaxes?: boolean;
  partAEffectiveDate?: string;
  partBEffectiveDate?: string;
  partnerId?: string;
  preferredName?: string;
  primaryAddressCity?: string;
  primaryAddressCounty?: string;
  primaryAddressFipsCode?: string;
  primaryAddressLineOne?: string;
  primaryAddressLineTwo?: string;
  primaryAddressState?: string;
  primaryAddressZipCode?: string;
  primaryPhoneNumber?: string;
  qualified?: boolean;
  secondaryPhoneNumber?: string;
  ssn?: string;
  subsidyPercentage?: number;
  subsidyType?: string;
  timezone?: TimeZones;
  userId?: string;
  zipCode?: string;
  noSaleCategory?: string;
  noSaleReason?: string;
}

/* This type corresponds to the lead / user info within the formik form */
export type Lead = {
  birthday?: string;
  desiredEffectiveDate: string;
  email: string;
  emailSetting?: EmailSetting;
  filedIndividualTaxes: string;
  firstName: string;
  followUpDate: string;
  gender: string;
  groupOver20?: boolean;
  hasMedicareCard: string;
  incomeBracket?: number;
  individualCoverage?: IndividualCoverageType;
  isSmoker: string;
  lastName: string;
  leadOwnerId: number;
  leadStatus: string;
  mailingAddressCity?: string;
  mailingAddressCounty?: string;
  mailingAddressFipsCode?: string;
  mailingAddressLineOne?: string;
  mailingAddressLineTwo?: string;
  mailingAddressState?: string;
  mailingAddressZipCode?: string;
  medicareNumber: string;
  middleInitial?: string;
  noSaleCategory: string;
  noSaleReason: string;
  notes: string;
  partAEffectiveDate: string;
  partBEffectiveDate: string;
  partnerId: number;
  plannedRetirement?: string;
  preferredName?: string;
  primaryAddressCity?: string;
  primaryAddressCounty?: string;
  primaryAddressFipsCode?: string;
  primaryAddressLineOne?: string;
  primaryAddressLineTwo?: string;
  primaryAddressState?: string;
  primaryAddressZipCode?: string;
  primaryPhoneNumber: string;
  secondaryPhoneNumber?: string;
  ssn: string;
  subsidyPercentage: number | null;
  subsidyType?: string;
  takingSocialSecurityBenefits: string;
  timezone: string;
  veteranCoverage?: boolean;
  agentId?: string;
};

export enum InputState {
  IDLE = 'IDLE',
  LOADING = 'LOADING',
  SAVED = 'SAVED',
}

export interface ICalendlyAppointment {
  name: CalendlyAppointments;
  slug: string;
  label: string;
  length: number;
}

export enum CalendlyAppointments {
  NEW_TO_MEDICARE_RR = 'NEW_TO_MEDICARE_RR',
  COSTCO_RR = 'COSTCO_RR',
  HOUR_RR = 'HOUR_RR',
  MEET_YOUR_MEDICARE_GUIDE = 'MEET_YOUR_MEDICARE_GUIDE',
  RETIREMENT_COVERAGE_GUIDANCE = 'RETIREMENT_COVERAGE_GUIDANCE',
  AEP_PDP_RR = 'AEP_PDP_RR',
  AEP_MAPD_RR = 'AEP_MAPD_RR',
}

type MarketData = {
  claims: number;
  lives: number;
  market_share: number;
  premiums: number;
  state: string | null;
};

type CompanyBase = {
  key: string;
  ambest_outlook: string;
  ambest_rating: string;
  business_type: string;
  customer_complaint_ratio: number | null;
  customer_satisfaction_ratio: number;
  default_resources: {
    [key: string]: {
      sso_e_app_link: string;
      e_app_link: string;
      get_contracted_link?: string;
    };
  };
  established_year: number;
  last_modified: string;
  med_supp_market_data: {
    med_supp_national_market_data: MarketData;
    med_supp_state_market_data: MarketData[];
    year: number;
  }[];
  med_supp_national_market_data: MarketData;
  med_supp_state_market_data: MarketData[];
  naic: string;
  name: string;
  name_full: string;
  parent_company: string;
  parent_company_base: {
    key: string;
    code: string;
    established_year: number;
    last_modified: string;
    name: string;
  };
  sp_rating: string;
  type: string;
  underwriting_data: any[];
};

type LocationBase = {
  key: string;
  county: string[];
  effective_date: string;
  expires_date: string;
  last_modified: string;
  state: string;
  zip3: any[];
  zip5: any[];
};

type RateIncrease = {
  date: string;
  rate_increase: number;
};

type Rate = {
  annual: number;
  month: number;
  quarter: number;
  semi_annual: number;
};

export type CsgQuoteType = {
  annual_plan_premium: string;
  carrier_name: string;
  enrollmentLinkType: string;
  monthly_premium: string;
  plan_name: string;
  rates: {
    annual: number;
    month: number;
    quarter: number;
    semi_annual: number;
  };
  fees: {
    name: string;
    type: string;
    value: number;
  }[];
  discounts: any[]; // This is an empty array in the provided data, so I used 'any[]'. Update if necessary.
  discountCategory: string;
  ageIncreases: number[];
  rateIncreases: any[]; // This is an empty array in the provided data, so I used 'any[]'. Update if necessary.
  companyName: string;
  companyLastModified: string;
  companySpRating: string;
  companyAmBestOutlook: string;
  companyAmBestRating: string;
  companyYearEstablished: number;
  companySatisfactionRate: number;
  companyComplaintRatio: number | null;
  companyMarketData: {
    med_supp_national_market_data: {
      claims: number;
      lives: number;
      market_share: number;
      premiums: number;
      state: string | null;
    };
    med_supp_state_market_data: any[]; // This is an empty array in the provided data, so I used 'any[]'. Update if necessary.
    year: number;
  }[];
  companyParentName: string;
  planObject: string;
  rateType: string;
  ratingClass: string;
  writingNumber: string | null;
  writingNumberError: string;
};

export enum ViewOptions {
  UPCOMING_APPOINTMENTS = 'UPCOMING_APPOINTMENTS',
  FOLLOW_UP_TODAY = 'FOLLOW_UP_TODAY',
  MISSING_QUALIFICATION = 'MISSING_QUALIFICATION',
  HOT_LEADS = 'HOT_LEADS',
}

export const WhereClauseToOrderBy = {
  [WhereClauseArgs.UPCOMING_APPOINTMENTS]: OrderByArgs.ORDER_BY_APPOINTMENT_DATE,
  [WhereClauseArgs.FOLLOW_UP_TODAY]: OrderByArgs.ORDER_BY_FOLLOW_UP_DATE,
  [WhereClauseArgs.MISSING_QUALIFICATION]: OrderByArgs.ORDER_BY_CREATED_AT,
} as const;

export enum SubsidyType {
  FULL_DUAL = 'FULL_DUAL',
  DUAL = 'DUAL',
  PARTIAL_SUBSIDY = 'PARTIAL_SUBSIDY',
  MEDICARE_SAVINGS_PROGRAM = 'MEDICARE_SAVINGS_PROGRAM',
  FULL_SUBSIDY_SOCIAL_SECURITY = 'FULL_SUBSIDY_SOCIAL_SECURITY',
}

export enum QuotingModalTypes {
  Compare = 'compare',
  ExtraInfo = 'extraInfo',
  PrescriptionCoverage = 'prescriptionCoverage',
  ProviderCoverage = 'providerCoverage',
  PharmacyCoverage = 'pharmacyCoverage',
  PlanDocuments = 'planDocuments',
}

export enum UserHistoryTypes {
  AIRCALL = 'AIRCALL',
  CALENDAR = 'CALENDAR',
  EMAIL = 'EMAIL',
  SOA = 'SOA',
  QUOTE = 'QUOTE',
  PAGE_VIEW = 'PAGE_VIEW',
  NPS_RESPONSE = 'NPS_RESPONSE',
  ENROLLMENT = 'ENROLLMENT',
}

export enum EmailEventTypes {
  DELIVERY = 'DELIVERY',
  OPEN = 'OPEN',
  LINK_CLICK = 'LINK_CLICK',
  SPAM_COMPLAINT = 'SPAM_COMPLAINT',
  BOUNCE = 'BOUNCE',
}

export enum CalendarEventTypes {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
}
