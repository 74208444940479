import { extendTheme } from '@chakra-ui/react';
import { DM_Sans } from '@next/font/google';

const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  style: 'normal',
  display: 'swap',
});

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const fonts = {
  body: dmSans.style.fontFamily,
  heading: dmSans.style.fontFamily,
  mono: dmSans.style.fontFamily,
};

const colors = {
  primary: {
    50: '#e4f0ff',
    100: '#bbd3f8',
    200: '#92b6ee',
    300: '#6899e4',
    400: '#3e7cdb',
    500: '#2463c1',
    600: '#194d97',
    700: '#0f376d',
    800: '#042144',
    900: '#000b1c',
  },
  blue: {
    50: '#f0f4fd',
    100: '#b8c8fd',
    200: '#89a4f6',
    300: '#5c7fef',
    400: '#2f5bea',
    500: '#335eea',
    600: '#0e33a3',
    700: '#072476',
    800: '#011649',
    900: '#1A365D',
  },
};

const components = {
  Button: {
    sizes: {
      xl: {
        py: '20px',
        px: '28px',
        fontSize: '20px',
      },
    },
  },
  Divider: {
    sizes: {
      xl: {
        height: '2px',
        backgroundColor: '#000',
      },
    },
  },
};

const textStyles = {
  hero: {
    fontSize: ['32px', '54px', '54px', '54px'],
  },
  hero_sub_header: {
    fontSize: ['25px', '25px', '25px', '25px'],
  },
  h1: {
    fontSize: ['3xl', '4xl', '5xl', '6xl'],
  },
  h2: {
    fontSize: ['xl', '2xl', '3xl', '4xl'],
  },
  h3: {
    fontSize: ['xl', '2xl', '3xl', '4xl'],
  },
  h4: {
    fontSize: ['xl', 'xl', '2xl', '2xl'],
  },
  h5: {
    fontSize: ['lg', 'xl', '2xl', '3xl'],
  },
  h6: {
    fontSize: ['lg', 'xl', '2xl', '3xl'],
    color: 'gray.500',
  },
  p: {
    fontSize: ['lg', 'lg', 'xl'],
  },
  p_lg: {
    fontSize: ['lg', 'xl', '2xl'],
  },
  emphasis: {
    fontSize: ['xl', '2xl'],
    fontWeight: 700,
  },
  wizardHeading: {
    fontSize: ['3xl', '3xl', '4xl'],
    mb: 4,
  },
  wizardText: {
    fontSize: ['lg', 'xl', '2xl'],
  },
};

const breakpoints = {
  sm: '20em',
  md: '48em',
  lg: '66em',
  xl: '80em',
};

const customTheme = extendTheme({
  config,
  colors,
  fonts,
  textStyles,
  components,
  breakpoints,
  namespace: 'og',
});

export default customTheme;
