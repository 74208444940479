import { Box } from '@chakra-ui/react';
import { FontSizes } from 'chakra-ui/fsmTheme';

const Body1 = ({ semibold = false, bold = false, children = null, ...rest }) => (
  <Box
    fontSize={[FontSizes.body1.mobile, FontSizes.body1.mobile, FontSizes.body1.desktop]}
    fontWeight={bold ? 700 : semibold ? 500 : 400}
    lineHeight="160%"
    letterSpacing=".2px"
    {...rest}
  >
    {children}
  </Box>
);

export default Body1;
