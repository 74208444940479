import posthog from 'posthog-js';

export enum VirgilHomePageVariants {
  control = 'control',
  virgil = 'virgil',
}

export const initPostHog = () => {
  if (typeof window !== 'undefined') {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_API_KEY, {
      api_host: 'https://app.posthog.com',
      loaded: () => {
        if (process.env.NEXT_PUBLIC_ANALYTICS_ENABLED === 'true') {
          posthog.opt_in_capturing();
        } else {
          posthog.opt_out_capturing();
        }
        if (process.env.DEBUG_POSTHOG === 'true') posthog.debug();
      },
    });
  }
};

export const captureArticleView = ({ tags }: { tags: string[] }) => {
  posthog.capture('Article View', { tags });
};
