import { FsmColors } from 'chakra-ui/fsmTheme';

export function numberWithCommas(x) {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
}

export enum TypeformLinks {
  cmsComplaint = 'https://form.typeform.com/to/p4WFshIi',
  stopSpam = 'https://form.typeform.com/to/g2fUmI8F',
  otc = 'https://fairsquaremedicare.typeform.com/to/tXD8EvqN',
}

export const numDaysBetween = function (date1, date2) {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return diff / (1000 * 60 * 60 * 24);
};

export const coreColors = [FsmColors.red, FsmColors.teal, FsmColors.blue, FsmColors.navy];

export const getRandomCoreColor = () => {
  const index = Math.floor(Math.random() * 4) % 3;
  const color = coreColors[index];
  return color;
};

export const capitalizeFirstletter = (string: string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const shuffle = (array) => {
  let currentIndex = array.length,
    randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
};

export const getPublishDate = (index) => new Date(2022, 10, 1 + index);

export const formatDollarsCents = (num: number) => {
  if (num === undefined || num === null) return 'Error';
  return `$${numberWithCommas(num.toFixed(2))}`;
};

export const formatDollars = (num: number) => {
  if (num === undefined || num === null) return 'Error';
  return `$${numberWithCommas(num.toFixed(0))}`;
};

export enum OpenbookCarriersEnum {
  Humana = 'HUMANA',
  Uhc = 'UHC',
  Aetna = 'AETNA',
  Anthem = 'ANTHEM',
  Cigna = 'CIGNA',
  Regence = 'REGENCE',
  MutualOmaha = 'MUTUAL_OMAHA',
  Bcbs = 'BCBS',
  Wellcare = 'WELLCARE',
  Gpm = 'GPM',
  BsCalifornia = 'BS_CALIFORNIA',
}

export const supportedCarriers: string[] = [OpenbookCarriersEnum.Humana, OpenbookCarriersEnum.Uhc];

export const OpenbookCarriers = {
  [OpenbookCarriersEnum.Humana]: {
    key: OpenbookCarriersEnum.Humana,
    name: 'Humana',
    label: 'Humana',
    image: '/ra/carrier-logos/humana.svg',
    auth: 'basic',
    value: OpenbookCarriersEnum.Humana,
  },
  [OpenbookCarriersEnum.Uhc]: {
    key: OpenbookCarriersEnum.Uhc,
    name: 'United Healthcare',
    label: 'United Healthcare',
    image: '/ra/carrier-logos/uhc.svg',
    auth: '2fa',
    value: OpenbookCarriersEnum.Uhc,
  },
  [OpenbookCarriersEnum.Aetna]: {
    key: OpenbookCarriersEnum.Aetna,
    name: 'Aetna',
    label: 'Aetna',
    image: '/ra/carrier-logos/aetna-purple.png',
  },
  [OpenbookCarriersEnum.Anthem]: {
    key: OpenbookCarriersEnum.Anthem,
    name: 'Anthem',
    label: 'Anthem',
    image: '/ra/carrier-logos/anthem-blue.png',
  },
  [OpenbookCarriersEnum.Cigna]: {
    key: OpenbookCarriersEnum.Cigna,
    name: 'Cigna',
    label: 'Cigna',
    image: '/ra/carrier-logos/cigna-color.png',
  },
  [OpenbookCarriersEnum.Regence]: {
    key: OpenbookCarriersEnum.Regence,
    name: 'Regence',
    label: 'Regence',
    image: '/ra/carrier-logos/regence.png',
  },
  [OpenbookCarriersEnum.MutualOmaha]: {
    key: OpenbookCarriersEnum.MutualOmaha,
    name: 'Mutual of Omaha',
    label: 'Mutual of Omaha',
    image: '/ra/carrier-logos/mutual-of-omaha.png',
  },
  [OpenbookCarriersEnum.Bcbs]: {
    key: OpenbookCarriersEnum.Bcbs,
    name: 'Blue Cross Blue Shield',
    label: 'Blue Cross Blue Shield',
    image: '/ra/carrier-logos/bcbs.webp',
  },
  [OpenbookCarriersEnum.Wellcare]: {
    key: OpenbookCarriersEnum.Wellcare,
    name: 'Wellcare',
    label: 'Wellcare',
    image: '/ra/carrier-logos/wellcare.png',
  },
  [OpenbookCarriersEnum.BsCalifornia]: {
    key: OpenbookCarriersEnum.BsCalifornia,
    name: 'Blue Shield of California',
    label: 'Blue Shield of California',
    image: '/ra/carrier-logos/bs-california.png',
  },
  [OpenbookCarriersEnum.Gpm]: {
    key: OpenbookCarriersEnum.Gpm,
    name: 'GPM',
    label: 'GPM',
    image: '/ra/carrier-logos/gpm.png',
  },
} as const;

export const OpenbookCarriersValues = Object.values(OpenbookCarriers);
export const OpenbookCarriersKeys = Object.keys(OpenbookCarriers);
