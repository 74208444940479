import { useMutation } from '@apollo/client';
import useLocalStorage from '@hooks/useLocalStorage';
import * as _ from 'lodash';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { useMode, isInternalUser } from '@contexts/ModeContextProvider';
import { createContext, useContext, useEffect, useState } from 'react';
import { UPSERT_USER } from '../gql/Users';
import { User } from '../interfaces';
import { CreationMethod } from '@interfaces/CreationMethod';

interface IWizardContext {
  user: User;
  setUser: (arg0: User) => void;
}

const WizardContext = createContext<IWizardContext>({} as IWizardContext);

const WizardContextProvider = ({ children }) => {
  const posthog = usePostHog();
  const mode = useMode();
  const router = useRouter();
  const [user, setUser] = useState<User>(undefined);
  const [userOnLocalStorage, setUserOnLocalStorage] = useLocalStorage('user', {
    email: '',
  });

  const [upsertUser, { loading }] = useMutation(UPSERT_USER, {
    fetchPolicy: 'no-cache',
    onCompleted: (data) =>
      setUser({
        ...user,
        id: data.upsertUser.id,
      }),
  });

  useEffect(() => {
    if (user?.id) {
      posthog.identify(user.id, {
        is_internal_user: isInternalUser(mode),
      });
    }
  }, [user?.id, user?.first_name, user?.email]);

  useEffect(() => {
    const year = new Date().getUTCFullYear();
    if (!loading && user && !_.isEqual(user, userOnLocalStorage) && router.pathname.includes('guide')) {
      upsertUser({
        variables: {
          id: user.id || 'temp',
          birthday: user?.birthday,
          creation_method: CreationMethod.WIZARD,
          email: user?.email,
          first_name: user?.first_name,
          has_medicare_number: user?.has_medicare_number,
          lower_bound: (user?.lower_bound && String(user?.lower_bound)) || null,
          upper_bound: (user?.upper_bound && String(user?.upper_bound)) || null,
          monthly_payment: (user?.monthly_payment && String(user?.monthly_payment)) || null,
          bracket_year: String(year),
          income_year: String(year - 2),
          primary_phone_number: user?.phone,
          paid_medicare_taxes: user?.paid_medicare_taxes,
          zip_code: user?.zip_code,
          gender: user?.gender,
        },
      });
    }
    setUserOnLocalStorage(user);
  }, [user]);

  useEffect(() => {
    setUser(userOnLocalStorage);
  }, []);

  return <WizardContext.Provider value={{ user, setUser }}>{children}</WizardContext.Provider>;
};

const useWizard = () => useContext(WizardContext);
export { WizardContextProvider as default, useWizard };
