import { Button as ChakraButton } from '@chakra-ui/button';
import { forwardRef, Box, Icon, Spinner } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Link from 'next/link';
import { useState } from 'react';

export enum ButtonVariants {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
  quinary = 'quinary',
}

const Button = forwardRef<any, any>(
  (
    {
      children = null,
      isLoading = null,
      isDisabled = false,
      fontSize = null,
      href = null,
      onClick = null,
      variant = 'primary',
      faIcon = null,
      ...rest
    },
    ref
  ) => {
    const [hovering, setHovering] = useState(false);
    const { width = null, w = null, ...restMinusWidth } = { ...rest };

    const wrapLink = (node) => (
      <Box w={width ?? w}>
        <Link href={href} target={rest.target} style={{ width: (width || w) && '100%', flex: rest.flex }}>
          {node}
        </Link>
      </Box>
    );

    const handleClick = async (e) => {
      if (onClick) {
        await onClick(e);
      }
    };

    const restToUse: any = href ? restMinusWidth : rest;

    const buttonComponent = (
      <ChakraButton
        ref={ref}
        onClick={handleClick}
        onMouseEnter={() => setHovering(true)}
        onMouseLeave={() => setHovering(false)}
        as="button"
        fontSize={fontSize}
        isDisabled={isDisabled}
        iconSpacing={children ? 1 : 0}
        leftIcon={faIcon && !isLoading && <Icon as={FontAwesomeIcon} icon={faIcon} />}
        variant={variant}
        {...restToUse}
        w={href && '100%'}
      >
        {isLoading ? <Spinner height="24px" /> : children}
      </ChakraButton>
    );

    return href ? wrapLink(buttonComponent) : buttonComponent;
  }
);

export default Button;
