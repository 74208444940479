import { useState } from 'react';
import { removeItem, setItem, getItem } from '@utils/localStorage';

export default function useLocalStorage(key, initialValue) {
  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState(() => {
    try {
      // Get from local storage by key
      const item = getItem(key);
      return item;
    } catch (_) {
      return initialValue;
    }
  });

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value) => {
    try {
      // Handle null value
      if (!value) {
        removeItem(key);
        setStoredValue(null);
        return;
      }

      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      setStoredValue(valueToStore);
      setItem(key, valueToStore);
    } catch (_) {}
  };

  return [storedValue, setValue];
}
