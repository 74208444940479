import { Box, Text } from '@chakra-ui/react';
import { FsmColors } from 'chakra-ui/fsmTheme';
import Link from 'next/link';

const TextLink = ({ text, href = null, onClick = null, icon = null, disabled = false, target = null, ...rest }) => {
  if (disabled) {
    return (
      <Box display="inline" {...rest}>
        <Text
          display="inline"
          fontSize={16}
          fontWeight={700}
          cursor="pointer"
          color={disabled ? FsmColors.disabled : FsmColors.black}
          textDecoration="underline"
          _hover={{
            textDecor: disabled ? 'underline' : 'none',
          }}
          {...rest}
        >
          {text}
        </Text>
        {icon}
      </Box>
    );
  }

  if (href) {
    return (
      <Link href={href} target={target} style={{ display: 'inline', ...rest }}>
        <Text
          display="inline"
          fontSize={16}
          fontWeight={700}
          cursor="pointer"
          color={disabled ? FsmColors.disabled : FsmColors.black}
          textDecoration="underline"
          _hover={{
            textDecor: disabled ? 'underline' : 'none',
          }}
          {...rest}
        >
          {text}
        </Text>
        {icon}
      </Link>
    );
  }

  return (
    <Box onClick={onClick} style={{ display: 'inline' }} {...rest}>
      <Text
        display="inline"
        fontSize={16}
        fontWeight={700}
        cursor="pointer"
        color={disabled ? FsmColors.disabled : FsmColors.black}
        textDecoration="underline"
        _hover={{
          textDecor: disabled ? 'underline' : 'none',
        }}
        {...rest}
      >
        {text}
      </Text>
      {icon}
    </Box>
  );
};
export default TextLink;
