import { Box, Fade, Flex, FormControl } from '@chakra-ui/react';
import TextInput from '@components/ra/inputs/TextInput';
import Button from '@components/ra/interactive/Button';
import { antlerStyle, transitionDuration } from '@constants/styles';
import { useVirgil } from '@contexts/VirgilContext';
import { Field, Form, Formik } from 'formik';
import { useMemo, useRef } from 'react';

const MessageInput = () => {
  // Resources
  const { inputRef, inputState, loadingAnswer, messageBuffer, onSubmit, socketConnected } = useVirgil();
  const formikRef = useRef();

  // Placeholder
  const placeholder = useMemo(() => {
    if (inputState) return 'Ask another question';
    return 'Ask any question about Medicare';
  }, [inputState]);

  return (
    <Flex
      flexDir="column"
      w="100%"
      maxWidth="680px"
      mx="auto"
      gap={4}
      position="absolute"
      bottom={0}
      pb={['40px', '40px', '80px']}
      px={[antlerStyle.mobilePx, antlerStyle.mobilePx]}
      overflow={'scroll'}
    >
      <Fade in style={{ width: '100%' }} transition={transitionDuration}>
        <Formik initialValues={{ question: '' }} onSubmit={onSubmit} innerRef={formikRef}>
          {() => (
            <Form style={{ marginTop: 'auto', zIndex: 3 }}>
              <Flex direction={{ base: 'column', sm: 'row' }}>
                <Field name="question">
                  {({ field }) => (
                    <FormControl pr={{ base: 0, sm: 5 }} style={{ zIndex: 3 }}>
                      <TextInput
                        autoFocus
                        bg="white"
                        fontSize={20}
                        id="question-input"
                        innerRef={inputRef}
                        name="question"
                        placeholder={placeholder}
                        required
                        type="text"
                        border="1px solid #ccc"
                        boxShadow="none !important"
                        {...field}
                      />
                    </FormControl>
                  )}
                </Field>
                <Button
                  alignItems="center"
                  isLoading={!socketConnected || loadingAnswer || messageBuffer.length !== 0}
                  isDisabled={!socketConnected || loadingAnswer || messageBuffer.length !== 0}
                  letterSpacing=".5px"
                  mt={{ base: 3, sm: 0 }}
                  type="submit"
                  zIndex={3}
                >
                  Send
                </Button>
              </Flex>
            </Form>
          )}
        </Formik>
        <Box
          backgroundImage="linear-gradient(rgba(255, 251, 245, 0), darkBackground, darkBackground)"
          height={[180, '106px']}
          width="calc(100% + 8px)"
          position="absolute"
          bottom={0}
          left="-4px"
          zIndex={2}
        />
      </Fade>
    </Flex>
  );
};

export default MessageInput;
