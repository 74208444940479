import { blogPattern, maPattern, msPattern, StorageKeys } from '@constants/index';
import { cleanPhoneNumber, PhoneNumbers, routeToNumber } from '@constants/phoneNumbers';
import { googlePhonePaths } from '@constants/routes';
import { numDaysBetween } from '@utils/index';
import { useRouter } from 'next/router';
import { createContext, useContext, useEffect, useState } from 'react';

// 99.6% market share acc. to https://gs.statcounter.com/search-engine-market-share/all/united-states-of-america
const searchEngineKeys = ['google', 'bing', 'yahoo', 'duckduckgo'];

interface ContextValues {
  dynamicPhoneNumber?: number;
}

const SemContext = createContext<ContextValues>({} as ContextValues);

const SemContextProvider = ({ children }) => {
  const router = useRouter();
  const [dynamicPhoneNumber, setDynamicPhoneNumber] = useState(null);
  const [isReplacementDone, setIsReplacementDone] = useState(false);

  const referrer = typeof document !== 'undefined' && document!.referrer;

  const userFromSearchResults = !!(referrer && searchEngineKeys.find((key) => referrer.includes(key)));

  const updateDynamicPhoneNumber = ({ phoneNumber }) => {
    window.localStorage.setItem(StorageKeys.dynamicPhoneNumber, JSON.stringify({ date: new Date(), phoneNumber }));
    setDynamicPhoneNumber(phoneNumber);
  };

  /*
    On initial render:
      1) If user is from search, update DPN for pages:
        - Google Ads Pages
        - Blog Pages
        - Ms Pages
        - Ma Pages

        Or, user has fresh DPN stored
      2) If route has specific phone, update DPN
      3) If user has fresh DPN stored, update DPN
  */
  useEffect(() => {
    const storageEntry = JSON.parse(window.localStorage.getItem(StorageKeys.dynamicPhoneNumber));
    const isEntryFresh = storageEntry && numDaysBetween(new Date(storageEntry.date), new Date()) < 30;

    const path = router.asPath.split('?')[0];

    // User from Search: If page has specific phone, update dpn
    if (userFromSearchResults) {
      if (googlePhonePaths.includes(path)) {
        updateDynamicPhoneNumber({ phoneNumber: PhoneNumbers.GOOGLE });
      } else if (blogPattern.test(path)) {
        updateDynamicPhoneNumber({ phoneNumber: PhoneNumbers.BLOG });
      } else if (msPattern.test(path)) {
        updateDynamicPhoneNumber({ phoneNumber: PhoneNumbers.MS_SEO });
      } else if (maPattern.test(path)) {
        updateDynamicPhoneNumber({ phoneNumber: PhoneNumbers.MA_SEO });
      } else if (isEntryFresh) {
        setDynamicPhoneNumber(storageEntry.phoneNumber);
      }
    }
    // User not from search but IS on path with specific number
    else if (routeToNumber[path]) {
      updateDynamicPhoneNumber({ phoneNumber: routeToNumber[path] });
    }
    // User not from search && not on path with specific number but DOES have fresh DPN stored
    else if (isEntryFresh) {
      setDynamicPhoneNumber(storageEntry.phoneNumber);
    }
  }, []);

  // Set DPN to be the phone number that Google swapped in
  useEffect(() => {
    if (!isReplacementDone) {
      const interval = setInterval(() => {
        const phoneLink = document.getElementsByClassName('phone-link')[0];
        if (phoneLink && phoneLink.innerHTML) {
          const cleanedPhoneNumber = cleanPhoneNumber(phoneLink.innerHTML);

          if (dynamicPhoneNumber && cleanedPhoneNumber !== dynamicPhoneNumber.toString()) {
            updateDynamicPhoneNumber({ phoneNumber: cleanedPhoneNumber });
            setIsReplacementDone(true);
          }
        }
      }, 500);

      return () => clearInterval(interval);
    }
  }, [dynamicPhoneNumber]);

  return <SemContext.Provider value={{ dynamicPhoneNumber }}>{children}</SemContext.Provider>;
};

const useSem = () => useContext(SemContext);
export { SemContextProvider as default, useSem };
