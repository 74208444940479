import { StyleConfig, extendTheme } from '@chakra-ui/react';
import localFont from '@next/font/local';

const geomanist = localFont({
  display: 'swap',
  src: [
    {
      path: './fonts/geomanist/geomanist-medium.woff2',
      style: 'normal',
    },
  ],
});

const neueMontreal = localFont({
  display: 'swap',
  src: [
    {
      path: './fonts/montreal/montreal-regular.woff2',
      weight: '500',
      style: 'normal',
    },
    {
      path: './fonts/montreal/montreal-semibold.woff2',
      weight: '600',
      style: 'normal',
    },
    {
      path: './fonts/montreal/montreal-bold.woff2',
      weight: '700',
      style: 'normal',
    },
  ],
});

const primary = {
  50: '#e4f0ff',
  100: '#bbd3f8',
  200: '#92b6ee',
  300: '#6899e4',
  400: '#3e7cdb',
  500: '#2463c1',
  600: '#194d97',
  700: '#0f376d',
  800: '#042144',
  900: '#000b1c',
};
const red = {
  50: '#FDEBE7',
  100: '#FAC8BD',
  200: '#F6A493',
  300: '#F38168',
  400: '#EF5D3E',
  500: '#EC3A13',
  600: '#BD2E0F',
  700: '#8D230C',
  800: '#5E1708',
  900: '#2F0C04',
};
const navy = {
  50: '#EFEEF7',
  100: '#D3CFE7',
  200: '#B6B0D8',
  300: '#9A92C9',
  400: '#7D73BA',
  500: '#6154AB',
  600: '#4D4389',
  700: '#3A3366',
  800: '#272244',
  900: '#131122',
};

export enum FsmColors {
  black = '#000',
  tan = '#fffbf5',
  primary = '#3f7ddb',
  darkBackground = '#fffbf5',
  white = '#fff',
  teal = '#3fb6db',
  blue = '#3f7ddb',
  navy = '#2f2953',
  red = '#ef5b3b',
  mint = '#eaf5e7',
  highlight = '#f3ffac',
  error = '#cd2a13',
  disabled = '#b1b1b1',
  success = '#4F9D69',
}

export const FontSizes = {
  header1: {
    desktop: 80,
    mobile: 48,
  },
  header2: {
    desktop: 52,
    mobile: 36,
  },
  header3: {
    desktop: 32,
    mobile: 24,
  },
  header4: {
    desktop: 28,
    mobile: 19,
  },
  body1: {
    desktop: 20,
    mobile: 18,
  },
  body2: {
    desktop: 16,
    mobile: 16,
  },
} as const;

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const fonts = {
  body: neueMontreal.style.fontFamily,
  heading: geomanist.style.fontFamily,
  mono: neueMontreal.style.fontFamily,
};

const colors = {
  primary,
  black: FsmColors.black,
  tan: FsmColors.tan,
  darkBackground: FsmColors.darkBackground,
  white: FsmColors.white,
  teal: FsmColors.teal,
  blue: FsmColors.blue,
  navy,
  red,
  mint: FsmColors.mint,
  highlight: FsmColors.highlight,
  error: FsmColors.error,
  disabled: FsmColors.disabled,
  success: FsmColors.success,
  secondary: FsmColors.red,
};

const components: Record<string, StyleConfig> = {
  Heading: {
    baseStyle: {
      fontWeight: 500,
      color: 'black',
    },
  },
  Button: {
    sizes: {
      xl: {
        py: '20px',
        px: '28px',
        fontSize: '20px',
      },
      sm: {
        py: 5,
        px: [3, 3, 7],
        fontSize: 16,
      },
      md: {
        py: '26px',
        paddingX: [6, 6, 10],
        fontSize: 18,
      },
    },
    baseStyle: {
      borderRadius: 900,
      fontSize: 18,
      display: 'flex',
      gap: 1,
      alignItems: 'center',
      whiteSpace: 'nowrap',
      justifyContent: 'center',
      paddingY: 3,
    },
    variants: {
      primary: {
        bg: 'navy.800',
        color: 'white',
        border: '2px solid',
        borderColor: 'navy.800',
        _hover: {
          bg: 'white',
          color: 'navy.800',
        },
      },
      secondary: {
        border: '2px solid',
        borderColor: 'navy.800',
        bg: 'white',
        color: 'navy.800',
        _hover: {
          bg: 'navy.800',
          color: 'white',
        },
      },
    },
  },
  Divider: {
    sizes: {
      xl: {
        height: '2px',
        backgroundColor: '#000',
      },
    },
  },
};

const textStyles = {
  hero: {
    fontSize: ['32px', '54px', '54px', '54px'],
  },
  hero_sub_header: {
    fontSize: ['25px', '25px', '25px', '25px'],
  },
  h1: {
    fontSize: ['3xl', '4xl', '5xl', '6xl'],
  },
  h2: {
    fontSize: ['xl', '2xl', '3xl', '4xl'],
  },
  h3: {
    fontSize: ['xl', '2xl', '3xl', '4xl'],
  },
  h4: {
    fontSize: ['xl', 'xl', '2xl', '2xl'],
  },
  h5: {
    fontSize: ['lg', 'xl', '2xl', '3xl'],
  },
  h6: {
    fontSize: ['lg', 'xl', '2xl', '3xl'],
    color: 'gray.500',
  },
  p: {
    fontSize: ['lg', 'lg', 'xl'],
  },
  p_lg: {
    fontSize: ['lg', 'xl', '2xl'],
  },
  emphasis: {
    fontSize: ['xl', '2xl'],
    fontWeight: 700,
  },
  wizardHeading: {
    fontSize: ['3xl', '3xl', '4xl'],
    mb: 4,
  },
  wizardText: {
    fontSize: ['lg', 'xl', '2xl'],
  },
};

const breakpoints = {
  sm: '30em', // 480px
  md: '48em', // 768px
  lg: '62em', // 992px
  xl: '80em', // 1280px
  '2xl': '96em',
};

const customTheme = extendTheme({
  config,
  colors,
  fonts,
  textStyles,
  components,
  breakpoints,
  namespace: 'fsm',
});

export default customTheme;
