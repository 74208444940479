export const defaultFsmOpenGraph = {
  title: 'Fair Square Medicare',
  description: 'Your guide to Medicare Parts A & B, Medicare Advantage, and Medigap. 100% free.',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://www.fairsquaremedicare.com/',
    site_name: 'Fair Square Medicare',
    description: 'Your medicare advisor for life.',
    images: [
      {
        url: 'https://fair-square-medicare.s3.us-west-1.amazonaws.com/fsm-opengraph.png',
        width: 1200,
        height: 630,
        alt: 'Fair Square Medicare - Your medicare advisor for life.',
      },
    ],
  },
};

export const defaultBedrockOpenGraph = {
  title: 'Bedrock Medicare',
  description: 'Focus on your clients, not the minutiae of Medicare.',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://www.bedrockmedicare.com',
    site_name: 'Bedrock Medicare',
    description: 'Focus on your clients, not the minutiae of Medicare.',
    images: [
      {
        url: 'https://fair-square-medicare.s3.us-west-1.amazonaws.com/bedrock-opengraph.png',
        width: 1200,
        height: 630,
        alt: 'Bedrock Medicare – Tech-enabled back office for medicare brokers.',
      },
    ],
  },
};
