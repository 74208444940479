import { Text } from '@chakra-ui/react';
import { FontSizes } from 'chakra-ui/fsmTheme';

const Body2 = ({ children, bold = false, semibold = false, ...rest }) => {
  return (
    <Text
      fontSize={[FontSizes.body2.mobile, FontSizes.body2.mobile, FontSizes.body2.desktop]}
      fontWeight={bold ? 700 : semibold ? 500 : 400}
      lineHeight="145%"
      {...rest}
    >
      {children}
    </Text>
  );
};

export default Body2;
