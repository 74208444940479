import { Button as ChakraButton } from '@chakra-ui/react';

export default function Button({ children, primary = false, ...rest }) {
  const { variant } = rest;
  return (
    <ChakraButton
      as="button"
      size="sm"
      colorScheme={variant === 'link' || primary ? 'primary' : 'gray'}
      variant={primary ? 'solid' : 'outline'}
      whiteSpace="nowrap"
      justifyContent="center"
      alignItems="center"
      {...rest}
    >
      {children}
    </ChakraButton>
  );
}
