import { PlanType } from '@enums/PlanType';

export type ConnecturePrescription = {
  drugID: number;
  drugTypeID: number;
  name: string;
};

export enum DataSourceOptions {
  LAPro = 'lapro',
  Csg = 'csg',
}

export enum AgencyName {
  FAIR_SQUARE = 'FAIR_SQUARE',
  BEDROCK = 'BEDROCK',
}

export type Dosages = {
  commonDaysOfSupply: number;
  commonMetricQuantity: number;
  commonUserQuantity: number;
  displayName: string;
  dosageFormName: string;
  dosageID: string;
  genericDosageID: string;
  isCommonDosage: boolean;
  isCommonPackage: boolean;
  labelName: string;
  packageQuantity: number;
  packageSizeUnitOfMeasure: string;
  packSize: number;
  referenceNDC: string;
};

export type PrescriptionResults = {
  data: { connecturePrescriptions: ConnecturePrescription[] };
};

export type PrescriptionDetailResults = {
  data: {
    dosages: Dosages[];
    drugID: number;
    drugTypeID: number;
    genericDrugName: string;
    genericDrugID: number;
    name: string;
  };
};

export type SendQuotesArgs = {
  userId: string;
  agentId: string;
  planType: PlanType;
  plans: {
    id: string;
    planName: string;
    carrierName: string;
    subType: string;
    monthlyPremium: string;
    annualPremium: string;
    outlineLink: string;
  };
};

export type SendSoaArgs = {
  userId: string;
  agentId: string;
  methodOfContact: string;
  dateOfAppointment: string;
  agentSignature: string;
};

export enum RecommendationAttribution {
  RECOMMENDED = 'RECOMMENDED',
}

export type PostEnrollArgs = {
  agentId: string;
  attribution?: RecommendationAttribution;
  carrierName: string;
  connecturePlanId: string;
  enrollmentType: string;
  isTest: boolean;
  planObject: any;
  userId: string;
  writingNumber: string;
};

export enum ProviderRole {
  PRIMARY = 'PRIMARY',
  SPECIALIST = 'SPECIALIST',
}

export enum ProviderType {
  INDIVIDUAL = 'individual',
  ORGANIZATION = 'organization',
}

export type ProviderSearchArgs = {
  npi?: string;
  radius?: string;
  searchString?: string;
  type?: ProviderType;
  zipCode?: string;
};

export type ProviderAddressUpdate = {
  addressLine1: string;
  addressLine2: string;
  city: string;
  npi: string;
  state: string;
  vericredAddressId: string;
  zipCode: string;
};

export type UpdateProviderArgs = {
  providerId: string;
  role: ProviderRole;
};

export type ScheduledEmails = {
  agentId: string;
  createdAt: string;
  htmlBody: string;
  id: string;
  isTest: boolean;
  jobId: string;
  scheduledDeliveryAt: string | null;
  sentAt: string | null;
  subject: string;
};

export type FrontConversation = {
  conversationId: string;
  createdAt: string;
  subject: string;
};

export type UpsertPrescriptionArgs = {
  agentId?: string;
  baseName?: string;
  dosageData: Dosages;
  drugID?: number;
  frequency?: string;
  generic?: boolean;
  id?: string | undefined;
  labelName: string;
  laproDrugId?: string;
  quantity?: number;
  userId: string;
  laproActive: boolean;
};

export type SilenceAEPArgs = {
  userId: string;
  silenceAEP: boolean;
};

export type InitializeOpenBookUserArgs = {
  email: string;
  carrier: string;
  username: string;
  password: string;
  isDevMode: string;
};

export type GetBookArgs = {
  orderBy: object;
  take: number;
  page: string;
  carrier: string;
  status: string;
  view: string;
};

export type RefreshCarrierArgs = {
  carrier: string;
};

export type AddCarrierArgs = {
  email: string;
  carrier: string;
  username: string;
  password: string;
  isDevMode: boolean;
};

export type GetCredentialsArgs = {
  carrier: string;
  email?: string;
};

export type PutCredentialsArgs = {
  carrier: string;
  token: string;
  userId: string;
};

export type BedrockOnboardArgs = {
  agency: {
    displayName: string;
    defaultEmail: string;
  };
  agent: {
    first_name: string;
    last_name: string;
    email: string;
  };
};
