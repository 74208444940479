import { captureException } from '@sentry/react';
import axios from 'axios';

export const sendToken = async (email: string) => {
  const clientDate = new Date().toLocaleTimeString('en-US', {
    hour: 'numeric',
    minute: '2-digit',
  });

  try {
    const res = await axios.post(`${process.env.GRAPHQL_SERVER_ENDPOINT}/auth/send-link`, { clientDate, email });
    return res;
  } catch (err) {
    captureException(err);
  }
};

export const verifyToken = async (token: string, currentUser?: string) => {
  try {
    const res = await axios.post(`${process.env.GRAPHQL_SERVER_ENDPOINT}/auth/verify-token`, { token, currentUser });
    return res;
  } catch (err) {
    captureException(err);
    return { data: { status: 'ERROR' } };
  }
};
