import { Box, Flex, Stack } from '@chakra-ui/react';
import Body1 from '@components/ra/typography/Body1';
import { FsmColors } from 'chakra-ui/fsmTheme';

export enum Role {
  User = 'user',
  Assistant = 'assistant',
}

const Message = ({ message }) => {
  const isUser = message.role === Role.User;

  return (
    <Flex justifyContent={isUser ? 'right' : 'left'} minHeight={10} direction="column">
      <Stack
        spacing={6}
        color={isUser ? 'white' : 'black'}
        bg={isUser ? FsmColors.navy : FsmColors.mint}
        ml={isUser && 'auto'}
        py={5}
        px={[5, 5, 6, 10]}
        borderRadius={isUser ? '20px 0px 20px 20px' : '0px 20px 20px 20px'}
        whiteSpace="pre-line"
        textAlign={isUser ? 'right' : 'left'}
      >
        <Body1>
          <Box dangerouslySetInnerHTML={{ __html: message.content.trim() }} />
        </Body1>
      </Stack>
    </Flex>
  );
};

export default Message;
