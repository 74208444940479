/* eslint-disable arrow-body-style */
import SpinnerPage from '@components/common/SpinnerPage';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import { useVirgil } from '@contexts/VirgilContext';
import DrawerChat from './drawer';
import FsmChat from './fsm';

const Chat = ({ isDrawer = false }) => {
  // Resources
  const { socketConnected } = useVirgil();
  const { loadingPlanDocuments } = useAuthenticatedContextProvider();

  // Loading State
  if (loadingPlanDocuments || !socketConnected) return <SpinnerPage />;

  if (isDrawer) {
    return <DrawerChat />;
  }

  return <FsmChat />;
};

export default Chat;
