import { Input } from '@chakra-ui/react';

const TextInput = ({ id, type, name, field = null, fontSize = 16, innerRef = null, autoComplete = null, ...rest }) => {
  return (
    <Input
      id={id}
      name={name}
      type={type}
      width="100%"
      autoComplete={autoComplete}
      ref={innerRef}
      {...field}
      bg="white"
      height={['60px', '60px', 16]}
      fontSize={fontSize}
      px={6}
      borderRadius={99}
      border="none"
      required
      boxShadow="0px 4px 64px 0px #0000001A"
      _focusWithin={{
        border: 'solid',
        boxShadow: '0px 4px 64px 0px #0000001A',
      }}
      {...rest}
    />
  );
};

export default TextInput;
