import { Drawer, DrawerContent, DrawerHeader, Flex } from '@chakra-ui/react';
import IconButton from '@components/crm/lead/shared/IconButton';
import { faClose } from '@fortawesome/free-solid-svg-icons';
import Chat from '../Chat';

const VirgilDrawer = ({ isVirgilOpen, setIsVirgilOpen }) => (
  <Drawer size="lg" placement="right" isOpen={isVirgilOpen} onClose={() => setIsVirgilOpen(false)}>
    <DrawerContent overflow="hidden" boxShadow="0 0 15px 4px #00000019">
      <DrawerHeader py={3} boxShadow="0 0 15px 4px #00000019" zIndex={1}>
        <Flex justifyContent="space-between" alignItems="center">
          Ask Virgil
          <IconButton label="Close" icon={faClose} onClick={() => setIsVirgilOpen(false)} />
        </Flex>
      </DrawerHeader>
      <Chat isDrawer />
    </DrawerContent>
  </Drawer>
);
export default VirgilDrawer;
