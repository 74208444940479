import { Spinner as ChakraSpinner } from '@chakra-ui/react';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import { FsmColors } from 'chakra-ui/fsmTheme';
import { useEffect, useState } from 'react';

const colors = [FsmColors.teal, FsmColors.blue, FsmColors.red, FsmColors.highlight];

const Spinner = ({ ...rest }) => {
  // Resources
  const { isBedrock } = useAuthenticatedContextProvider();
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prev) => (prev + 1) % colors.length);
    }, 1500);

    return () => clearInterval(interval);
  }, []);

  return (
    <ChakraSpinner
      width={'60px'}
      height={'60px'}
      thickness="5px"
      color={isBedrock ? '#805AD5' : colors[index]}
      transition="all ease-in-out 1500ms"
      {...rest}
    />
  );
};

export default Spinner;
