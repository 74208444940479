import { StateCode } from './StateCode';
import { StateName } from './StateName';

export const stateCodeToName = {
  [StateCode.AL]: StateName.Alabama,
  [StateCode.AK]: StateName.Alaska,
  [StateCode.AZ]: StateName.Arizona,
  [StateCode.AR]: StateName.Arkansas,
  [StateCode.CA]: StateName.California,
  [StateCode.CO]: StateName.Colorado,
  [StateCode.CT]: StateName.Connecticut,
  [StateCode.DE]: StateName.Delaware,
  [StateCode.FL]: StateName.Florida,
  [StateCode.GA]: StateName.Georgia,
  [StateCode.HI]: StateName.Hawaii,
  [StateCode.ID]: StateName.Idaho,
  [StateCode.IL]: StateName.Illinois,
  [StateCode.IN]: StateName.Indiana,
  [StateCode.IA]: StateName.Iowa,
  [StateCode.KS]: StateName.Kansas,
  [StateCode.KY]: StateName.Kentucky,
  [StateCode.LA]: StateName.Louisiana,
  [StateCode.ME]: StateName.Maine,
  [StateCode.MD]: StateName.Maryland,
  [StateCode.MA]: StateName.Massachusetts,
  [StateCode.MI]: StateName.Michigan,
  [StateCode.MN]: StateName.Minnesota,
  [StateCode.MS]: StateName.Mississippi,
  [StateCode.MO]: StateName.Missouri,
  [StateCode.MT]: StateName.Montana,
  [StateCode.NE]: StateName.Nebraska,
  [StateCode.NV]: StateName.Nevada,
  [StateCode.NH]: StateName.NewHampshire,
  [StateCode.NJ]: StateName.NewJersey,
  [StateCode.NM]: StateName.NewMexico,
  [StateCode.NY]: StateName.NewYork,
  [StateCode.NC]: StateName.NorthCarolina,
  [StateCode.ND]: StateName.NorthDakota,
  [StateCode.OH]: StateName.Ohio,
  [StateCode.OK]: StateName.Oklahoma,
  [StateCode.OR]: StateName.Oregon,
  [StateCode.PA]: StateName.Pennsylvania,
  [StateCode.RI]: StateName.RhodeIsland,
  [StateCode.SC]: StateName.SouthCarolina,
  [StateCode.SD]: StateName.SouthDakota,
  [StateCode.TN]: StateName.Tennessee,
  [StateCode.TX]: StateName.Texas,
  [StateCode.UT]: StateName.Utah,
  [StateCode.VT]: StateName.Vermont,
  [StateCode.VA]: StateName.Virginia,
  [StateCode.WA]: StateName.Washington,
  [StateCode.WV]: StateName.WestVirginia,
  [StateCode.WI]: StateName.Wisconsin,
  [StateCode.WY]: StateName.Wyoming,
  [StateCode.DC]: StateName.DistrictOfColumbia,
};
