import { createContext, useContext, useEffect, useState } from 'react';
import { getItem, setItem, removeItem } from '@utils/localStorage';

export enum Mode {
  DEFAULT = 'default',
  DEMO = 'demo',
  DEV = 'dev',
}

export const ModeToColor = {
  [Mode.DEFAULT]: 'blue',
  [Mode.DEMO]: 'teal',
  [Mode.DEV]: 'red.500',
};

export const isInternalUser = (mode: Mode) => mode !== Mode.DEFAULT;

const ModeContext = createContext<Mode>({} as Mode);

const ModeContextProvider = ({ children }) => {
  const [mode, setMode] = useState(Mode.DEFAULT);

  // Demo Mode: Shift + Ctrl + D
  // Dev Mode: Shift + Ctrl + X
  useEffect(() => {
    const localMode = getItem('mode');

    if (localMode) {
      setMode(localMode);
    }

    document.addEventListener('keydown', (e) => {
      if (e.shiftKey && e.ctrlKey) {
        // Dev mode is toggled with Shift+X
        if (e.keyCode === 88) {
          setMode((prev) => {
            if (prev === Mode.DEV) {
              removeItem('mode');
              return Mode.DEFAULT;
            }
            setItem('mode', Mode.DEV);
            return Mode.DEV;
          });
        } else if (e.keyCode === 68) {
          setMode((prev) => {
            if (prev === Mode.DEMO) {
              removeItem('mode');
              return Mode.DEFAULT;
            }
            setItem('mode', Mode.DEMO);
            return Mode.DEMO;
          });
        }
      }
    });
  }, []);

  return <ModeContext.Provider value={mode}>{children}</ModeContext.Provider>;
};

const useMode = () => useContext(ModeContext);
export { ModeContextProvider as default, useMode };
