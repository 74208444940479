import { Heading } from '@chakra-ui/react';
import { FontSizes } from 'chakra-ui/fsmTheme';

const Header2 = ({ children, ...rest }) => {
  return (
    <Heading
      as="h2"
      fontSize={[FontSizes.header2.mobile, FontSizes.header2.mobile, FontSizes.header2.desktop]}
      letterSpacing="-1px"
      lineHeight="110%"
      {...rest}
    >
      {children}
    </Heading>
  );
};

export default Header2;
