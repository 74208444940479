import { Flex, useToast } from '@chakra-ui/react';
import Body2 from '@components/ra/typography/Body2';
import bee from '@public/flaticons/bee.png';
import checkmark from '@public/ra/icons/checkmark.svg';
import Image from 'next/image';

const useFsmToast = () => {
  const toast = useToast();

  const successToast = ({ text, toastId = undefined }) => {
    toast({
      duration: 3000,
      position: 'bottom',
      id: toastId,
      render: () => (
        <Flex
          bg="highlight"
          py={4}
          px={8}
          mb={12}
          borderRadius={99}
          alignItems="center"
          gap={3}
          width="fit-content"
          mx="auto"
        >
          <Image src={checkmark} alt="Checkmark" />
          <Body2 bold>{text}</Body2>
        </Flex>
      ),
    });
  };

  const bugToast = ({ text, toastId = undefined }) => {
    toast({
      duration: 3000,
      position: 'bottom',
      id: toastId,
      render: () => (
        <Flex
          bg="highlight"
          py={4}
          px={8}
          mb={12}
          borderRadius={99}
          alignItems="center"
          gap={4}
          width="fit-content"
          mx="auto"
          boxShadow="0px 4px 40px 0px #0000002A"
        >
          <Image src={bee} alt="Bumble Bee Winking" width={40} height={40} />
          <Body2 bold maxW="300px">
            {text}
          </Body2>
        </Flex>
      ),
    });
  };

  return {
    successToast,
    bugToast,
  };
};

export default useFsmToast;
