import { gql } from '@apollo/client';

export const UPSERT_USER = gql`
  mutation(
    $id: String!
    $birthday: DateTime
    $creation_method: CreationMethod
    $email: String
    $has_medicare_number: Boolean
    $paid_medicare_taxes: Boolean
    $lower_bound: Decimal
    $upper_bound: Decimal
    $monthly_payment: Decimal
    $bracket_year: String
    $income_year: String
    $primary_phone_number: String
    $first_name: String
    $zip_code: String
    $gender: String
  ) {
    upsertUser(
      id: $id
      birthday: $birthday
      creation_method: $creation_method
      email: $email
      first_name: $first_name
      primary_phone_number: $primary_phone_number
      income_year: $income_year
      bracket_year: $bracket_year
      monthly_payment: $monthly_payment
      lower_bound: $lower_bound
      upper_bound: $upper_bound
      has_medicare_number: $has_medicare_number
      paid_medicare_taxes: $paid_medicare_taxes
      zip_code: $zip_code
      gender: $gender
    ) {
      id
    }
  }
`;
