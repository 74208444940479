import VirgilDrawer from '@components/common/virgil/VirgilDrawer';
import { useAuthenticatedContextProvider } from '@contexts/AuthenticatedContextProvider';
import { Mode, useMode } from '@contexts/ModeContextProvider';
import useFsmToast from '@hooks/useCustomToast';
import { nodeSocket as socket } from '@lib/sockets';
import { cmsPublications } from '@utils/cmsPublications';
import { capitalizeFirstletter } from '@utils/index';
import { FormikHelpers } from 'formik/dist/types';
import { useRouter } from 'next/router';
import { usePostHog } from 'posthog-js/react';
import { v4 as uuidv4 } from 'uuid';
import { createContext, useCallback, useContext, useEffect, useRef, useState } from 'react';

type VirgilContextType = {
  isVirgilOpen: boolean;
  setIsVirgilOpen: React.Dispatch<React.SetStateAction<any>>;
  socketConnected: boolean;
  chatStackRef: any;
  inputRef: any;
  inputState: any;
  loadingAnswer: boolean;
  messageHistory: any;
  onSubmit: (values: { question: any }, formikHelpers: FormikHelpers<{ question: any }>) => void | Promise<any>;
  conversationId: any;
  devContent: any;
  emitQuestion: ({ question, conversationId }: { question: string; conversationId: string }) => void;
  messageBuffer: string[];
};

type SocketMessage = {
  content?: string;
  followUp?: string;
  dev?: string;
};

export enum Role {
  User = 'user',
  Assistant = 'assistant',
}

const VirgilContext = createContext<VirgilContextType>({} as VirgilContextType);

const VirgilProvider = ({ children }) => {
  // Resources
  const router = useRouter();
  const posthog = usePostHog();
  const { authenticatedUser: user } = useAuthenticatedContextProvider();
  const { bugToast } = useFsmToast();
  const mode = useMode();
  const chatStackRef = useRef(null);
  const inputRef = useRef(null);
  const userId = user?.id;

  // State
  const [isVirgilOpen, setIsVirgilOpen] = useState(false);
  const [socketConnected, setSocketConnected] = useState(false);
  const [conversationId, setConversationId] = useState(null);
  const [devContent, setDevContent] = useState({});
  const [loadingAnswer, setLoadingAnswer] = useState(false);
  const [messageBuffer, setMessageBuffer] = useState([]);
  const [messageHistory, setMessageHistory] = useState([]);
  const [inputState, setInputState] = useState(null);
  const [eventIdState, setEventIdState] = useState(null);
  const [scrollHeight, setScrollHeight] = useState(null);

  useEffect(() => {
    if (chatStackRef.current) {
      const newScrollHeight = chatStackRef.current.scrollHeight;
      if (scrollHeight !== newScrollHeight) {
        // Update scrollHeight state if it has changed
        setScrollHeight(newScrollHeight);
      }
    }
  });

  // Connect + Disconnect Socket
  useEffect(() => {
    // Don't connect socket in cypress tests.
    const isCypress = 'Cypress' in window;
    if (isCypress) return;

    socket.connect();
    return () => {
      socket.disconnect();
    };
  }, []);

  useEffect(() => {
    if (chatStackRef && chatStackRef.current) {
      chatStackRef.current.scrollTo({
        top: chatStackRef.current.scrollHeight,
        behavior: 'smooth',
      });
    }
  }, [chatStackRef, chatStackRef.current]);

  // Format Answer
  const formatResponse = useCallback((string) => {
    let formattedString = string;

    const regex = /(\s?\(?\s?)\{\{([\w-]+)\s*(?: PAGE )?\s*(\d+)(?:-(\d+))?\}\}(\s?\)?)/g;
    formattedString = string.replace(regex, (_, before, documentKey, startPage, endPage, after) => {
      return `${before} (<a href="${cmsPublications[documentKey]}#page=${startPage}" style="color: #335eea; font-weight: 500;" target="_blank">Source</a>)${after}`;
    });

    // Replace bullet points with bold text
    const bulletPointRegex = /^(\d+)\.\s([^:]+):$/gm;
    formattedString = formattedString.replace(bulletPointRegex, '$1. <b>$2</b>:');

    // Handle hyphens followed by a capital letter
    // Only add a newline if it's not the start of the string or if there isn't already a newline
    const hyphenRegex = /(?:^|\n)(-\s[A-Z])/g;
    formattedString = formattedString.replace(hyphenRegex, (match, p1) => {
      return match.startsWith('\n') ? match : `\n${p1}`;
    });

    // Convert Markdown bold (**) to HTML bold (<b></b>)
    const markdownBoldRegex = /\*\*(.+?)\*\*/g;
    formattedString = formattedString.replace(markdownBoldRegex, '<b>$1</b>');

    return formattedString;
  }, []);

  // Update Response Interval
  useEffect(() => {
    const updateResponse = () => {
      if (messageBuffer.length) {
        const message = messageBuffer[0];
        setMessageBuffer((prevBuffer) => prevBuffer.slice(1)); // remove the first element

        const updateMessageHistory = (history) => {
          if (!history.length) {
            return [{ role: Role.Assistant, content: message }];
          }

          const lastMessage = history[history.length - 1];
          let newContent = `${lastMessage.content}${message}`;

          // Check if User message
          if (lastMessage.role === Role.User) {
            return [...history, { role: Role.Assistant, content: message }];
          }

          const copy = [...history];
          const newContentFormatted = formatResponse(newContent);
          copy[copy.length - 1].content = newContentFormatted;
          return copy;
        };

        setMessageHistory((prevHistory) => updateMessageHistory(prevHistory));
        window.scrollTo(0, 0);
      }
    };

    const updateInterval = setInterval(updateResponse, 0);

    return () => {
      clearInterval(updateInterval);
    };
  }, [messageBuffer, inputState]);

  // Push to Message Buffer
  const onNewMessage = async (message: SocketMessage) => {
    if (message.dev) {
      const parsed = message.dev.replace('[DEV]: ', '');
      const data = JSON.parse(parsed);
      setDevContent((prev) => {
        return { ...prev, ...data };
      });
    }

    if (message.content) {
      setMessageBuffer((prevBuffer) => [...prevBuffer, message.content]);
    }
  };

  const formatQuestion = (question: string) => {
    const trimmedQuestion = question.trim();
    return capitalizeFirstletter(trimmedQuestion);
  };

  // Emit question event
  const emitQuestion = ({ question, conversationId }) => {
    const formattedQuestion = formatQuestion(question);

    setLoadingAnswer(true);
    setInputState(question);

    if (eventIdState) socket.off(eventIdState, onNewMessage);

    const eventId = uuidv4();
    socket.on(eventId, onNewMessage);
    setEventIdState(eventId);

    const isTest = mode !== Mode.DEFAULT;
    socket.emit('question', {
      question,
      eventId,
      conversationId,
      isTest,
      userId,
      posthogId: posthog.get_distinct_id(),
      path: typeof window !== 'undefined' && window.location.href,
    });
    posthog.capture('VA Question', {
      question,
      conversationId,
      isTest,
      userId,
      id: eventId,
    });
    setMessageHistory((history) => [...history, { role: Role.User, content: formattedQuestion }]);
  };

  // Setup Socket
  useEffect(() => {
    // Create Conversation ID.
    const conversationId = uuidv4();
    setConversationId(conversationId);

    // Handle question in query param
    const { question } = router.query;
    if (question && typeof question === 'string') {
      emitQuestion({ question, conversationId });
    }

    const onConnect = () => setSocketConnected(true);
    const onDisconnect = () => setSocketConnected(false);
    const onAnswerComplete = () => setLoadingAnswer(false);
    const onError = ({ content }) => {
      bugToast({ text: content });
      setLoadingAnswer(false);
    };

    socket.on('connect', onConnect);
    socket.on('disconnect', onDisconnect);
    socket.on('answerComplete', onAnswerComplete);
    socket.on('error', onError);

    return () => {
      socket.off('answerComplete', onAnswerComplete);
      socket.off('error', onError);
      socket.off('connect', onConnect);
      socket.off('disconnect', onDisconnect);
    };
  }, [socket]);

  // Handle question submission
  const onSubmit = useCallback(
    ({ question }, { resetForm }) => {
      emitQuestion({ question, conversationId });

      // Reset input
      resetForm();

      // Blur input (hides keyboard on mobile)
      if (inputRef && inputRef.current) {
        inputRef.current.blur();
      }
    },
    [inputState, mode, conversationId]
  );

  const handleScrollToBottom = () => {
    if (chatStackRef && chatStackRef.current) {
      const chatStackElement = chatStackRef.current;
      const isScrolledToBottom =
        chatStackElement.scrollHeight - chatStackElement.scrollTop <= chatStackElement.clientHeight + 220;

      // User has manually scrolled away from the bottom
      if (!isScrolledToBottom && messageHistory[messageHistory.length - 1].role === Role.Assistant) {
        return;
      }

      chatStackElement.scrollTo({
        top: chatStackElement.scrollHeight,
        behavior: 'smooth',
      });
    }
  };

  // Scroll
  useEffect(handleScrollToBottom, [scrollHeight]);

  const contextValue = {
    chatStackRef,
    conversationId,
    devContent,
    emitQuestion,
    inputRef,
    inputState,
    isVirgilOpen,
    loadingAnswer,
    messageBuffer,
    messageHistory,
    onSubmit,
    setIsVirgilOpen,
    socketConnected,
  };

  return (
    <VirgilContext.Provider value={contextValue}>
      {children}
      <VirgilDrawer isVirgilOpen={isVirgilOpen} setIsVirgilOpen={setIsVirgilOpen} />
    </VirgilContext.Provider>
  );
};

export const useVirgil = () => useContext(VirgilContext);

export default VirgilProvider;
