import { PhoneIcon } from '@chakra-ui/icons';
import { Box, Flex, Stack } from '@chakra-ui/react';
import Button, { ButtonVariants } from '@components/ra/interactive/Button';
import Body1 from '@components/ra/typography/Body1';
import Header2 from '@components/ra/typography/Header2';
import { PhoneNumbers, hyphenatePhoneNumber } from '@constants/phoneNumbers';
import { useIsMobile } from '@hooks/useIsMobile';

const Header = () => {
  const isMobile = useIsMobile();

  return (
    <Stack w="100%" spacing={5} position="relative">
      <Flex w="100%" justifyContent="space-between" alignItems="center">
        <Header2>
          Virgil
          <Box display="inline-block" as="span" ml={[2, 2, 2, 3]}>
            <Box
              width={['12px', '12px', '16px', '18px']}
              height={['12px', '12px', '16px', '18px']}
              bgColor="secondary"
            />
          </Box>
        </Header2>
        <Button Icon={PhoneIcon} href={`tel:${PhoneNumbers.VIRGIL}`} variant={ButtonVariants.secondary}>
          Call Us {!isMobile && `: ${hyphenatePhoneNumber(PhoneNumbers.VIRGIL)}`}
        </Button>
      </Flex>
      <Stack spacing={4}>
        <Body1>
          Virgil is your personal Medicare assistant. Ask him about your plan, how much a procedure will cost, or
          anything about Medicare in general. He can do it all.
        </Body1>
      </Stack>
    </Stack>
  );
};

export default Header;
