export const navHeight = 94;
export const textInputHeight = ['72px', '72px', '89px']; // Height prevents hint from moving content (60 + 17, 64 + 25)

export const navXMargin = [4, 8, 16, '5%'];

export const navMaxWidth = '1200px';

export const fontSize = {
  hero: ['32px', '54px', '54px', '54px'],
  blogTitle: ['28px', '36px', '42px'],
  h1: ['3xl', '4xl', '5xl', '6xl'],
  h2: ['xl', '2xl', '3xl', '4xl'],
  h3: ['xl', '2xl', '3xl', '3xl'],
  h4: ['xl', '2xl', '2xl', '2xl'],
  h5: ['lg', 'xl', '2xl', '3xl'],
  h6: ['lg', 'xl', '2xl', '3xl'],
  p: ['md', 'lg', 'xl'],
  p_lg: ['xl', 'xl', 'xl', '2xl'],
  emphasis: ['xl', '2xl'],
  wizardHeading: ['3xl', '3xl', '4xl'],
  wizardText: ['lg', 'xl', '2xl'],
};

export const antlerNavHeight = {
  mobile: 94,
  desktop: 94,
};

export const bedrockNavHeight = {
  mobile: 47,
  desktop: 47,
};

export const antlerStyle = {
  px: ['24px', '24px', '40px', '100px'],
  desktopPx: '100px',
  tabletPx: '24px',
  mobilePx: '24px',
  navHeight: '94px',
  bedrockHeroHeight: [
    `calc(100vh - ${bedrockNavHeight.mobile - 10}px)`,
    `calc(100vh - ${bedrockNavHeight.mobile - 10}px)`,
    `calc(100vh - ${bedrockNavHeight.mobile - 10}px)`,
    `calc(100vh - ${bedrockNavHeight.desktop - 10}px)`,
  ],
  heroHeight: [
    `calc(100vh - ${antlerNavHeight.mobile}px)`,
    `calc(100vh - ${antlerNavHeight.mobile}px)`,
    `calc(100vh - ${antlerNavHeight.mobile}px)`,
    `800px`,
  ],
  textInputShadow: '0px 4px 64px 0px #0000001A',
  boxShadow: '0px 0px 40px rgba(0 0 0 / .09)',
  border: ['6px solid', '6px solid', '10px solid', '10px solid'],
  sectionPy: ['72px', '72px', '72px', '160px'],
};

export const defaultTransition = 'ease-in-out 0.3s';

export const transitionDuration = {
  enter: {
    duration: 0.4,
  },
  exit: {
    duration: 0.4,
  },
};
