import { Box, Flex, Icon, Modal, ModalContent, ModalHeader, ModalOverlay, Stack, Text } from '@chakra-ui/react';
import Header4 from '@components/bedrock/typography/Header4';
import Link from '@components/bedrock/typography/Link';
import Button from '@components/crm/lead/shared/Button';
import IconButton from '@components/crm/lead/shared/IconButton';
import Body1 from '@components/ra/typography/Body1';
import { useVirgil } from '@contexts/VirgilContext';
import { faClose, faRobot } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { cmsPublications } from '@utils/cmsPublications';
import { useState } from 'react';
import { titleCase } from 'title-case';
import MessageHistory from './MessageHistory';
import MessageInput from './MessageInput';

const DrawerChat = () => {
  // Resources
  const { chatStackRef } = useVirgil();
  const uniqueDocuments = new Set(Object.values(cmsPublications));

  // State
  const [modalOpen, setModalOpen] = useState(false);

  return (
    <Box overflow="scroll" ref={chatStackRef} id="chat-stack">
      <MessageInput />
      <Flex flexDir="column" w="100%" gap={6} flexGrow={1} pb={['140px', '94px', '90px']} p={4}>
        <Stack p={4} alignItems="center">
          <Body1>
            Virgil has access to
            <Button mx={2} onClick={() => setModalOpen(true)}>
              {uniqueDocuments.size} documents
            </Button>
            on Medicare. Ask it a question and it'll look for the answer in these documents.
          </Body1>
          <Box py="40px">
            <Icon as={FontAwesomeIcon} icon={faRobot} color="var(--chakra-colors-primary-200)" width={20} height={20} />
          </Box>
        </Stack>
        <MessageHistory />
      </Flex>
      <Modal isOpen={modalOpen} onClose={() => setModalOpen(false)} size="xl">
        <ModalOverlay />
        <ModalContent>
          <ModalHeader display="flex" justifyContent="space-between" pb={0}>
            <Header4>Virgil Documents</Header4>
            <IconButton icon={faClose} onClick={() => setModalOpen(false)} />
          </ModalHeader>
          <Stack p={4} spacing={3}>
            {Array.from(uniqueDocuments).map((url) => (
              <Flex gap={2}>
                <Text pt={1}>–</Text>
                <Link key={url} href={url} color="primary.600" target="_blank">
                  {titleCase(url.split('/').pop().replace('.pdf', '').replace(/-/g, ' ').replace(/\d/g, ' '))}
                </Link>
              </Flex>
            ))}
          </Stack>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DrawerChat;
