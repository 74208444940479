import { Box } from '@chakra-ui/react';
import NextLink from 'next/link';

const Link = ({ children, href, target = null, ...rest }) => (
  <NextLink href={href} target={target}>
    <Box
      textStyle="body1"
      textDecoration="underline"
      fontWeight={700}
      display="inline"
      _hover={{ textDecor: 'none' }}
      {...rest}
    >
      {children}
    </Box>
  </NextLink>
);

export default Link;
