import { StyleConfig, SystemStyleObjectRecord, Tooltip, extendTheme } from '@chakra-ui/react';
import { DM_Sans } from '@next/font/google';

const dmSans = DM_Sans({
  weight: ['400', '500', '700'],
  subsets: ['latin'],
  style: 'normal',
  display: 'swap',
});

const config = {
  useSystemColorMode: false,
  initialColorMode: 'light',
};

const fonts = {
  body: dmSans.style.fontFamily,
  heading: dmSans.style.fontFamily,
  mono: dmSans.style.fontFamily,
};

export enum BedrockColors {
  primary = '#805AD5',
  black = '#000',
  darkBackground = '#faf9fe',
}

const colors = {
  primary: {
    50: '#FAF5FF',
    100: '#00000007',
    200: '#D6BCFA',
    300: '#B794F4',
    400: '#9F7AEA',
    500: '#805AD5',
    600: '#6B46C1',
    700: '#553C9A',
    800: '#44337A',
    900: '#322659',
  },
  lime: {
    '50': '#f7f9e0',
    '100': '#dee87c',
    '200': '#c1cf36',
    '300': '#a2ae2d',
    '400': '#919b28',
    '500': '#7a8322',
    '600': '#676e1d',
    '700': '#535917',
    '800': '#464b13',
    '900': '#32360e',
  },
  darkBackground: BedrockColors.darkBackground,
  highlight: '#f3ffac',
  secondary: '#EE6352',
  tertiary: '#231942',
  // bright: '#39B0C6',
};

const components: Record<string, StyleConfig> = {
  Button: {
    sizes: {
      xl: {
        py: '20px',
        px: '28px',
        fontSize: '20px',
      },
    },
    baseStyle: {
      gap: 1,
    },
    variants: {
      primary: {
        bg: 'primary.500',
        color: 'white',
        border: '1px solid',
        borderColor: 'primary.500',
        _hover: {
          bg: 'white',
          color: 'primary.500',
        },
      },
      secondary: {
        border: '1px solid',
        borderColor: 'gray.200',
        bg: 'white',
        color: 'black',
        _hover: {
          bg: 'gray.100',
        },
      },
      badge: {
        border: '1px solid',
        borderColor: 'primary.500',
        bg: 'primary.50',
        color: 'primary.500',
        _hover: {
          bg: 'gray.100',
        },
      },
    },
  },
  Divider: {
    sizes: {
      xl: {
        height: '2px',
        backgroundColor: '#000',
      },
    },
  },
  Box: {
    baseStyle: {
      textStyle: 'body1',
    },
  },
  Tooltip: {
    baseStyle: {
      bg: 'white',
      color: 'black',
      border: '1px solid',
      borderColor: 'primary.500',
      fontSize: '14px',
      fontWeight: '500',
      px: '8px',
      py: '4px',
    },
    defaultProps: {
      // @ts-ignore
      openDelay: 2000,
    },
  },
};

const textStyles: SystemStyleObjectRecord = {
  h1: {
    fontWeight: 600,
    fontSize: [40, 48, 48],
  },
  h2: {
    fontWeight: 600,
    fontSize: [30, 36, 36],
  },
  h3: {
    fontWeight: 600,
    fontSize: [24, 26, 26],
  },
  h4: {
    fontWeight: 600,
    fontSize: [20, 20, 20],
  },
  body1: {
    fontSize: 18,
  },
  body2: {
    fontSize: [14, 16, 16],
  },
};

const breakpoints = {
  sm: '20em',
  md: '48em',
  lg: '66em',
  xl: '80em',
};

const customTheme = extendTheme({
  config,
  colors,
  fonts,
  textStyles,
  components,
  breakpoints,
  namespace: 'bedrock',
});

Tooltip.defaultProps = {
  openDelay: 500,
} as any;

export default customTheme;
