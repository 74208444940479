import { gql } from '@apollo/client';

export const GET_PLAN_DOCUMENTS = gql`
  query {
    getPlanDocuments {
      planDocuments {
        cmsId
        documents {
          Name
          LinkName
          Url
        }
      }
      error {
        status
        message
      }
    }
  }
`;
