import { Button as ChakraButton, Icon, Tooltip } from '@chakra-ui/react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export default function IconButton({ icon, label = null, onClick = null, isDisabled = false, ...rest }) {
  const wrapInTooltip = (children) => {
    return <Tooltip label={label}>{children}</Tooltip>;
  };

  const button = (
    <ChakraButton
      onClick={onClick}
      as="button"
      fontWeight={700}
      borderWidth={1}
      variant="outline"
      display="flex"
      w={10}
      justifyContent="center"
      isDisabled={isDisabled}
      {...rest}
    >
      <Icon as={FontAwesomeIcon} icon={icon} />
    </ChakraButton>
  );

  return label ? wrapInTooltip(button) : button;
}
